import { Formik } from "formik"
import React, { forwardRef } from "react"
import { Col, Input, Row } from "reactstrap"
import * as yup from "yup"
import ErrorHandler from "../../../components/Alerts/ErrorHandler"
import CommonModal from "../../../components/Modals/CommonModal"

const schema = yup.object({
    name: yup.string().required("Name is required")
})

const ActionModal = (props: any, ref: any) => {
    const { targetMedia, onConfirm } = props
    const confirmText = targetMedia?.key ? "Update" : "Create"
    const header = targetMedia?.key ? "Rename" : "Folder"
    return (
        <Formik
            initialValues={{ name: targetMedia?.name }}
            enableReinitialize
            validationSchema={schema}
            onSubmit={(values, actions) => {
                onConfirm && onConfirm(values)
                actions.resetForm()
            }}
        >
            {formikProps => {
                const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit
                } = formikProps
                return (
                    <CommonModal
                        header={header}
                        confirmText={confirmText}
                        onConfirm={handleSubmit}
                        ref={ref}
                    >
                        <Row>
                            <Col md={12}>
                                <Input
                                    name="name"
                                    placeholder={`Enter your ${
                                        targetMedia?.key ? "file" : "folder"
                                    } name`}
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur("question")}
                                />
                                {touched.name && errors.name && (
                                    <ErrorHandler
                                        text={errors.name as string}
                                    />
                                )}
                            </Col>
                        </Row>
                    </CommonModal>
                )
            }}
        </Formik>
    )
}

export default forwardRef(ActionModal)
