import {
    FILTER,
    TINY_MCE_KEY,
  } from "../containers/TinyMCEKey/constants/types";
  import { api } from "./api";
  
  const TINY_MCE_KEY_URL = "/api/tiny-mce";
  
  export const get = (filter?: FILTER) =>
    api.get(TINY_MCE_KEY_URL, { params: filter });
  
  export const getById = (id: number) =>
    api.get(`${TINY_MCE_KEY_URL}/${id}`);
  
  export const create = (data: TINY_MCE_KEY) =>
    api.post(TINY_MCE_KEY_URL, data);
  
  export const update = (data: TINY_MCE_KEY) =>
    api.put(`${TINY_MCE_KEY_URL}/${data.id}`, data);
  
  export const remove = (id: number) =>
    api.delete(`${TINY_MCE_KEY_URL}/${id}`);
  