import React, { FC, useRef } from "react"
import useLanguageList from "../hooks/useLanguageList"
import useLanguageDetail from "../hooks/useLanguageDetail"
import LanguageDetailModal from "../components/LanguageDetailModal"
import { Col, Row, Table } from "reactstrap"
import { AiFillEdit } from "react-icons/ai"
import DeleteButtonIcon from "../../../components/Buttons/DeleteButtonIcon"
import SearchBoxContainer from "../../../components/Inputs/SearchBoxContainer"
import { Language } from "../../../types/Language"

const DEFAULT_LANGUAGE = 'en-US';

const LanguageList: FC = () => {
  const modal = useRef<any>()

  const {
    languageList,
    totalItems,
    filter,
    changeFilter,
    removeData,
    getData
  } = useLanguageList()

  const {
    id,
    setId,
    confirmData,
    languageDetail,
    reset,
    file, 
    setFile,
    setEditedTrue
  } = useLanguageDetail()

  const openModal = (id?: number) => {
    !!id ? setId(id) : setId(0)
    modal && modal.current && modal.current.toggle()
  }

  const onConfirm = (values: any) => {
    confirmData(values, () => {
      modal && modal.current && modal.current.toggle()
      getData()
    })
  }

  return (
    <div className="animated fadeIn">
      <LanguageDetailModal
          ref={modal}
          header={!!id ? "Edit" : "Create"}
          confirmText={!!id ? "Update" : "Create"}
          onConfirm={onConfirm}
          onCancel={reset}
          adminTipDetail={languageDetail}
          file={file}
          setFile={setFile}
          setEditedTrue={setEditedTrue}
        />
      <h4>Language List</h4>
      <Row className="mb-2">
        
        <Col md={8}>
            {totalItems <= 5 &&

              <SearchBoxContainer
                text="Add language"
                onClick={() => openModal()}
                initValue={filter.search}
                onSearch={(string) => changeFilter({ search: string })}
              />
          }
        </Col>
        <Col md={4} className="d-flex justify-content-end align-items-center">
          <span className="font-weight-bold">
            Total language: {totalItems}
          </span>
        </Col>
      </Row>
      <Table responsive bordered striped hover size="sm" className="mb-2">
        <thead>
          <tr>
            <th>#</th>
            <th>Language</th>
            <th>Code</th>
            <th>IsActive</th>
            <th className="text-center" style={{width: "100px"}}></th>
          </tr>
        </thead>
        <tbody>
          {languageList.length > 0 && languageList.map((lang: Language, key: number) => (
            <tr key={lang.id}>
              <td>{ ++key }</td>
              <td>{lang.name}</td>
              <td>{lang.code}</td>
              <td>{lang.isActive ? "Active" : "Is Active"}</td>
              
              <td className="text-center">
                {
                    lang.code != DEFAULT_LANGUAGE && (
                        <React.Fragment>
                            <AiFillEdit
                                className="mr-2 cursor-pointer hover-opacity"
                                onClick={() => openModal(lang.id)}
                            />

                            <DeleteButtonIcon
                                onClick={() => {
                                    removeData(Number(lang.id))
                                }}
                            />
                        </React.Fragment>
                    )
                }
                
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
export default LanguageList