import React from "react";
import { Form, Input, Label } from "reactstrap";
import { IFormGuide } from "../constants/type";

const FormGuide = ({
  title,
  inputId,
  onSelectFile,
  inputLink,
  onChangeLink,
}: IFormGuide) => {
  return (
    <div id={inputId.toString()}>
      <Label className="font-weight-bold">{title}:</Label>
      <div className="d-flex">
        <Input
          name={inputId.toString()}
          placeholder="Enter link video"
          value={inputLink}
          type="text"
          onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
            onChangeLink?.(inputId, event.target.value);
          }}
        />
        <Form>
          <Input
            className="d-none"
            type="file"
            id={`${inputId}-id`}
            onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
              const file = event?.target?.files?.[0];
              if (file) {
                await onSelectFile?.(file, inputId);
              }
            }}
            accept=".mp4"
          />
          <div className="d-flex align-items-center px-3 rounded border ml-3 h-100">
            <Label
              htmlFor={`${inputId}-id`}
              role="button"
              tabIndex={0}
              className="text-nowrap m-0 font-weight-bold"
            >
              Choose video
            </Label>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default FormGuide;
