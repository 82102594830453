import React, { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { setUser } from "../redux/commons/action"
import { ACCESS_TOKEN } from "../utils/constants"
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
} from "@coreui/react"
import CIcon from "@coreui/icons-react"

const DEFAULT_USER_AVATAR = "/avatars/avatar.png"

const TheHeaderDropdown = () => {
  const user = useSelector((state: any) => state.commons.user)
  const dispatch = useDispatch()
  const history = useHistory()

  const signOut = useCallback(() => {
    localStorage.removeItem(ACCESS_TOKEN)
    dispatch(setUser(null))
    history.push("/login")
  }, [])

  const avatarUrl = DEFAULT_USER_AVATAR

  return (
    <CDropdown inNav className="c-header-nav-items mx-2">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          <CImg
            src={avatarUrl}
            className="c-avatar-img"
            alt="admin@bootstrapmaster.com"
          />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem>
          <CIcon name="cil-user" className="mfe-2" />
          {user?.email}
        </CDropdownItem>
        <CDropdownItem onClick={signOut}>
          <CIcon name="cil-account-logout" className="mfe-2" />
          Sign out
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default TheHeaderDropdown
