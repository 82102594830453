import { api } from "./api"
import { NotificationFilter } from "../interfaces/NotificationFilter"

const NOTIFICATION_URL = "/api/notification"

export const get = (filter: NotificationFilter) =>
  api.get(NOTIFICATION_URL, { params: filter })

export const toggleIsRead = (id: number) => api.put(`${NOTIFICATION_URL}/${id}`)

export const countUnreadNotifications = () =>
  api.get(`${NOTIFICATION_URL}/unread`)

export const responseToRequestChangePlanAPI = (data: any) =>
  api.post(`${NOTIFICATION_URL}/responseToRequestChangePlan`, data)
