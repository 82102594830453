import { useState, useEffect, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { Filter } from "../../../types/Filter"
import { School } from "../../../types/School"
import { DEFAULT_FILTER } from "../../../utils/constants"
import { setLoading, setAlert, setModal } from "../../../redux/commons/action"
import { setSchoolList } from "../../../redux/schools/action"

import { get, remove } from "../../../services/schoolService"

const SCHOOL_DETAIL_URL = "/schools"
const CREATE_SCHOOL_URL = "/create-school"
const useSchoolList = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [filter, setFilter] = useState<Filter>(DEFAULT_FILTER)
  const schoolList: School[] = useSelector(
    (state: any) => state.schools.schoolList
  )
  const totalItems: number = useSelector(
    (state: any) => state.schools.totalItems
  )

  const changeFilter = (valueObject: any) =>
    setFilter({
      ...filter,
      ...valueObject,
    })

  const getData = useCallback(async () => {
    dispatch(setLoading(true))

    try {
      const res = await get(filter)
      const { totalItems, items } = res.data
      dispatch(setSchoolList({ items, totalItems }))
    } catch (err) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data || err.message,
        })
      )
    }

    dispatch(setLoading(false))
  }, [filter])

  const removeData = useCallback(
    async (id: number) => {
      dispatch(
        setModal({
          isOpen: true,
          type: "warning",
          message: "Do you want to delete this school?",
          onConfirm: async () => {
            dispatch(setLoading(true))

            try {
              await remove(id)
              changeFilter({ page: 1 })
              dispatch(
                setAlert({
                  type: "success",
                  message: "Delete school successfully",
                })
              )
            } catch (err) {
              dispatch(
                setAlert({
                  type: "danger",
                  message: err.response?.data || err.message,
                })
              )
            }

            dispatch(setLoading(false))
          },
        })
      )
    },
    [getData]
  )

  const goToDetail = useCallback((id?: number) => {
    const url = id ? `${SCHOOL_DETAIL_URL}/${id}` : CREATE_SCHOOL_URL
    history.push(url)
  }, [])

  useEffect(() => {
    getData()
  }, [filter])

  return {
    SCHOOL_DETAIL_URL,
    schoolList,
    totalItems,
    filter,
    removeData,
    changeFilter,
    goToDetail,
  }
}

export default useSchoolList
