import React, { FC } from "react"
import { Row, Col, Table, CustomInput } from "reactstrap"
import { FaCheck, FaTimes } from "react-icons/fa"

import SearchBox from "../../../components/Inputs/SearchBox"
import CustomPagination from "../../../components/Pagination/CustomPagination"
import EmptyDataAlert from "../../../components/Alerts/EmptyDataAlert"
import NotificationStatusSelector from "../components/NotificationStatusSelector"

import utcToLocalTime from "../../../utils/utcToLocalTime"
import { FULL_DATE_FORMAT } from "../../../utils/constants"

import useNotificationList from "../hooks/useNotificationList"

const header = "Notification list"

const NotificationList: FC = () => {
  const {
    notificationList,
    totalItems,
    filter,
    changeFilter,
    toggleStatus,
    responseToRequestChangePlan,
  } = useNotificationList()

  return (
    <div className="animated fadeIn">
      <h5 className="mb-2">{header}</h5>
      <Row className="my-2">
        <Col md={6}>
          <SearchBox
            initValue={filter.search}
            onSearch={(search?: any) =>
              changeFilter({
                search: search,
              })
            }
            placeholder="Type something to search"
          />
        </Col>
        <Col md={2}>
          <NotificationStatusSelector
            value={filter.isRead}
            onChange={(e: any) =>
              changeFilter({ isRead: (e && e.value) || null })
            }
          />
        </Col>
      </Row>
      <Row className="my-2 d-flex justify-content-center">
        <Col md={4} className="d-flex justify-content-end">
          Total of notifications: {totalItems}
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={8}>
          {notificationList && notificationList.length > 0 ? (
            <div>
              <Table bordered hover striped responsive size="sm">
                <thead>
                  <tr>
                    <th className="align-top" style={{width: "150" }}>
                      Time
                    </th>
                    <th className="align-top">Text</th>
                    <th className="text-center">Is read</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {notificationList.map((record: any) => (
                    <tr key={record.id}>
                      <td className="align-middle">
                        {utcToLocalTime(record.createdAt, FULL_DATE_FORMAT)}
                      </td>
                      <td className="align-middle">{record.text}</td>
                      <td className="text-center align-middle">
                        {record.source != "RequestChangePlan" && (
                          <CustomInput
                            type="checkbox"
                            id={`status-checkbox-${record.id}`}
                            checked={record.isRead}
                            onChange={() => toggleStatus(record.id)}
                          />
                        )}
                      </td>
                      <td>
                        {!record.isRead &&
                          record.source == "RequestChangePlan" && (
                            <div className="d-flex justify-content-around">
                              <FaCheck
                                title="Approve"
                                className="cursor-pointer"
                                onClick={() => {
                                  let data = JSON.parse(record.extraData)
                                  data.isApproved = true
                                  return responseToRequestChangePlan(
                                    record.id,
                                    data
                                  )
                                }}
                              />
                              <FaTimes
                                title="Reject"
                                className="cursor-pointer"
                                onClick={() => {
                                  let data = JSON.parse(record.extraData)
                                  data.isApproved = false
                                  responseToRequestChangePlan(record.id, data)
                                }}
                              />
                            </div>
                          )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <EmptyDataAlert label="notification" />
          )}
        </Col>
        <Col md={8}>
          <CustomPagination
            page={filter.page}
            limit={filter.limit}
            totalItems={totalItems}
            changePage={(page: number) => changeFilter({ page })}
            changeLimit={(limit: number) => changeFilter({ limit })}
          />
        </Col>
      </Row>
    </div>
  )
}

export default NotificationList
