import React from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from "@coreui/react"

import { setSidebar } from "../redux/commons/action"

import Images from "../assets/constants/Images"

const TheSidebar = ({ navigation }: any) => {
  const dispatch = useDispatch()
  const show: any = useSelector((state: any) => state.commons.sidebarShow)

  return (
    <CSidebar
      show={show as any}
      onShowChange={(val: any) => dispatch(setSidebar(val))}
    >
      <CSidebarBrand className="d-md-down-none" to="/">
        <img src={Images.LOGO} className="sidebar-logo c-sidebar-brand-full" />
        <img
          src={Images.LOGO_MINIMIZED}
          className="sidebar-logo c-sidebar-brand-minimized"
        />
      </CSidebarBrand>
      <CSidebarNav>
        <CCreateElement
          items={navigation}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer id="sidebar-minimizer" className="c-d-md-down-none" />
    </CSidebar>
  )
}

export default TheSidebar
