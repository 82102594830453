import React, { FC } from "react"
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap"
import { S3Object } from "../constants/gallery.types"
import MediaNav from "../components/MediaNav"
import Media from "../components/Media"
import Filter from "../components/Filter"
import useGalleryList from "../hooks/useGalleryList"

interface Props {
    height: string
}

const MediaList: FC<Props> = ({ height }: Props) => {
    const {
        filters,
        mediaList,
        targetMedia,
        router,
        breadcrumb,
        changeFilters,
        handleOpenFolder,
        handleSelectMedia,
        handleSelectFile,
        handleCreateFolder,
        handleRenameFile,
        handleDownloadMedia,
        handleDeleteMediaOrFolder,
        handleGoBack,
        showErrorAlert
    } = useGalleryList()

    const renderList = () =>
        mediaList.map((media: S3Object) => {
            const isTarget = targetMedia?.key === media.key

            return (
                <Col className="p-2" xs="auto" key={media.key}>
                    <div
                        onDoubleClick={() => handleOpenFolder(media)}
                        onClick={() => handleSelectMedia(media)}
                        className="media-wrapper"
                    >
                        <Media media={media} isTarget={isTarget} />
                    </div>
                </Col>
            )
        })

    return (
        <Card>
            <CardHeader>
                <Filter changeFilters={changeFilters} filters={filters} />
                <MediaNav
                    step={router.step}
                    breadcumb={breadcrumb}
                    currentPrefix={filters.prefix || ""}
                    targetMedia={targetMedia}
                    handleSelectFile={handleSelectFile}
                    handleCreateFolder={handleCreateFolder}
                    handleRenameFile={handleRenameFile}
                    handleDownloadMedia={handleDownloadMedia}
                    handleDeleteMediaOrFolder={handleDeleteMediaOrFolder}
                    handleGoBack={handleGoBack}
                    showErrorAlert={showErrorAlert}
                />
            </CardHeader>
            <CardBody style={{ minHeight: height }}>
                <Row className="p-1">{renderList()}</Row>
            </CardBody>
        </Card>
    )
}

export default MediaList
