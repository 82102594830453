import React, { FC } from "react"
import { Row, Col } from "reactstrap"
import { useSelector } from "react-redux"
import MediaList from "../components/MediaList"
import MediaDetail from "../components/MediaDetail"

const header = "Gallery"

const GalleryList: FC = () => {
    const targetMedia = useSelector((state: any) => state.gallery.targetMedia)

    return (
        <div className="fadeIn animated">
            <h5 className="mb-2">{header}</h5>
            <Row className="mb-2">
                <Col md={8}>
                    <MediaList height="100vh" />
                </Col>
                <Col md={4}>
                    <MediaDetail
                        key={targetMedia?.key}
                        targetMedia={targetMedia}
                    />
                </Col>
            </Row>
        </div>
    )
}
export default GalleryList
