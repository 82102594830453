import { useState, useEffect, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import Pusher from "pusher-js/with-encryption"

import { NotificationFilter } from "../../../interfaces/NotificationFilter"
import { Notification } from "../../../types/Notification"
import { DEFAULT_FILTER } from "../../../utils/constants"
import { setLoading, setAlert, setModal } from "../../../redux/commons/action"
import { setNotificationList } from "../../../redux/notifications/action"

import {
  get,
  toggleIsRead,
  responseToRequestChangePlanAPI,
} from "../../../services/notificationService"
import { PUSHER_CLUSTER, PUSHER_KEY } from "../../../utils/constants"

const NOTIFICATION_CHANNEL = "NOTIFICATION_CHANNEL"
const NEW_NOTIFICATION = "NEW_NOTIFICATION"

const useNotificationList = () => {
  const dispatch = useDispatch()
  const [filter, setFilter] = useState<NotificationFilter>({
    ...DEFAULT_FILTER,
    isRead: null,
  })
  const notificationList: Notification[] = useSelector(
    (state: any) => state.notifications.notificationList
  )
  const totalItems: number = useSelector(
    (state: any) => state.notifications.totalItems
  )

  const changeFilter = (valueObject: any) =>
    setFilter({
      ...filter,
      ...valueObject,
    })

  const getData = useCallback(async () => {
    dispatch(setLoading(true))

    try {
      const res = await get(filter)
      const { totalItems, items } = res.data
      dispatch(setNotificationList({ items, totalItems }))
    } catch (err) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data || err.message,
        })
      )
    }

    dispatch(setLoading(false))
  }, [filter])

  const toggleStatus = useCallback(
    (id: number) => {
      dispatch(
        setModal({
          isOpen: true,
          type: "warning",
          message: "Do you want to change this notification status?",
          onConfirm: async () => {
            dispatch(setLoading(true))

            try {
              await toggleIsRead(id)
              await getData()
            } catch (err) {
              dispatch(
                setAlert({
                  type: "danger",
                  message: err.response?.data || err.message,
                })
              )
            }

            dispatch(setLoading(false))
          },
        })
      )
    },
    [getData]
  )

  const responseToRequestChangePlan = (id: number, data: any) => {
    dispatch(
      setModal({
        isOpen: true,
        type: "warning",
        message: `Do you want to ${
          data.isApproved ? "approve" : "reject"
        } school to change to this plan?`,
        onConfirm: async () => {
          dispatch(setLoading(true))

          try {
            await responseToRequestChangePlanAPI(data)
            await toggleIsRead(id)
            await getData()
            dispatch(
              setAlert({
                type: "success",
                message: `${
                  data.isApproved ? "Approve" : "Reject"
                } successfully `,
              })
            )
          } catch (err) {
            dispatch(
              setAlert({
                type: "danger",
                message: err.response?.data || err.message,
              })
            )
          }

          dispatch(setLoading(false))
        },
      })
    )
  }

  useEffect(() => {
    getData()
    const pusher = new Pusher(PUSHER_KEY, { cluster: PUSHER_CLUSTER })
    const channel = pusher.subscribe(NOTIFICATION_CHANNEL)

    channel.bind(NEW_NOTIFICATION, getData)
    return () => pusher.unsubscribe(NOTIFICATION_CHANNEL)
  }, [filter])

  return {
    notificationList,
    totalItems,
    filter,
    changeFilter,
    toggleStatus,
    responseToRequestChangePlan,
  }
}

export default useNotificationList
