import { useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { setAlert, setLoading } from "../../../redux/commons/action";
import { create, getById, update } from "../../../services/languageService";
import { Language } from "../../../types/Language";
import { systemUpload } from "../../../services/schoolService"

const initValue: Language = {
  name: "",
  code: "",
  logo: "",
  isActive: false,
  rtl: false
}

const useLanguageDetail = () => {
  const dispatch = useDispatch()

  const [id, setId] = useState(0)
  const [languageDetail, setLanguageDetail] = useState<Language>(initValue)
  const [file, setFile] = useState<any>(null)
  const [edited, setEdited] = useState<boolean>(false)

  const setEditedTrue = async () => {
    localStorage.setItem(window.location.href, "true")
    setEdited(true)
  }

  useEffect(() => {
    !!id ? getData() : setLanguageDetail(initValue)
  }, [id])

  const reset = () => {
    setLanguageDetail(initValue)
    setId(0)
  }

  const getData = useCallback(async () => {
    dispatch(setLoading(true))

    try {
      const res = await getById(id)
      setLanguageDetail(res.data)
    } catch (err: any) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data?.title || err.message,
        })
      )
    }
    dispatch(setLoading(false))
  }, [id])

  const confirmData = async (values: any, cb: any) => {
      dispatch(setLoading(true))
      try {
        if (!!file) {
          const formData = new FormData()
          const { name } = file
          formData.append("file", file, name)
          const res = await systemUpload(formData)
          const imageUrl = res?.data?.key
          values.logo = imageUrl
      }

        !!id ? await update(values) : await create(values)
        
        dispatch(
          setAlert({
            type: "success",
            message: `${!!id ? "Update" : "Create"} language successfully`,
          })
        )

        cb && cb()
        !!id && setId(0)
      } catch (err: any) {
        dispatch(
          setAlert({
            type: "danger",
            message: err.response?.data || err.message,
          })
        )
      }
      dispatch(setLoading(false))
    }

  return {
    id, 
    setId,
    languageDetail,
    confirmData,
    initValue,
    reset,
    file, 
    setFile,
    setEditedTrue
  }
}

export default useLanguageDetail