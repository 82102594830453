import React, {
    FC,
    useState
    // , useRef
} from "react"
import {
    Col,
    // FormGroup,
    // Input,
    // Label,
    Row
} from "reactstrap"
import CommonSelector from "../../../components/Selectors/CommonSelector"
import { FilterType, MediaType } from "../constants/gallery.types"
// import DatePicker from "react-datepicker"
// import { getTime, format } from "date-fns"
// import { DATE_FORMAT } from "../../../utils/constants"
// import useGalleryDetail from "../hooks/galleryDetail"
import SearchBox from "../../../components/Inputs/SearchBox"

interface FilterInterface {
    changeFilters: (filter: FilterType) => void
    filters: FilterType
}

type MediaOption = {
    value: MediaType
    label: string
}

const options: MediaOption[] = [
    { value: MediaType.IMAGE, label: "Images" },
    { value: MediaType.AUDIO, label: "Audios" },
    { value: MediaType.DOC, label: "Docs" },
    { value: MediaType.VIDEO, label: "Videos" }
]

const Filter: FC<FilterInterface> = ({
    changeFilters
    // filters
}) => {
    const [queryTypes, selectQueryTypes] = useState<MediaOption[]>()
    return (
        <Row className="filter-media-file">
            <Col sm={8}>
                <SearchBox
                    initValue=""
                    onSearch={(searchString?: any) =>
                        changeFilters({
                            searchString: searchString
                        })
                    }
                    placeholder="Type something to search by file name"
                />
            </Col>

            <Col sm={4}>
                <CommonSelector
                    value={queryTypes}
                    isMulti
                    options={options}
                    onChange={(options: MediaOption[]) => {
                        selectQueryTypes(options)
                        const types = options.map((op: MediaOption) => op.value)
                        changeFilters({ types })
                    }}
                />
            </Col>
            {/* <Col sm={4}>
                <FormGroup>
                    <Label>From date</Label>
                    <DatePicker
                        wrapperClassName={`${styles["date-time-input"]} w-100`}
                        value={
                            !!filters.fromDate
                                ? format(filters.fromDate, DATE_FORMAT)
                                : undefined
                        }
                        todayButton="Today"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="From date"
                        onChange={(date: Date) => {
                            changeFilters({
                                fromDate: getTime(date)
                            })
                        }}
                        customInput={<Input type="text" />}
                    />
                </FormGroup>
            </Col>
            <Col sm={4}>
                <FormGroup>
                    <Label>To date</Label>
                    <DatePicker
                        wrapperClassName={`${styles["date-time-input"]} w-100`}
                        value={
                            !!filters.toDate
                                ? format(filters.toDate, DATE_FORMAT)
                                : undefined
                        }
                        todayButton="Today"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="To date"
                        onChange={(date: Date) => {
                            changeFilters({
                                toDate: getTime(date)
                            })
                        }}
                        customInput={<Input type="text"/>}
                    />
                </FormGroup>
            </Col> */}
        </Row>
    )
}

export default Filter
