import React, { FC, useRef } from "react"
import { Row, Col, Button, Label } from "reactstrap"
import {
    FaFolderPlus,
    FaFileUpload,
    FaArrowLeft,
    FaDownload,
    FaTrash,
    FaEdit
} from "react-icons/fa"
import ActionModal from "./ActionModal"
import { S3Object } from "../constants/gallery.types"

interface MediaNavInterface {
    step: number
    breadcumb: string
    currentPrefix: string
    targetMedia: S3Object
    handleSelectFile: Function
    handleCreateFolder: Function
    handleRenameFile: Function
    handleGoBack: () => void
    handleDownloadMedia: () => void
    handleDeleteMediaOrFolder: () => void
    showErrorAlert: (message: string) => void
}

const MediaNav: FC<MediaNavInterface> = ({
    step,
    breadcumb,
    currentPrefix,
    targetMedia,
    handleSelectFile,
    handleCreateFolder,
    handleRenameFile,
    handleGoBack,
    handleDownloadMedia,
    handleDeleteMediaOrFolder,
    showErrorAlert
}) => {
    const modal = useRef<any>()
    const inputFile = useRef<HTMLInputElement>(null)

    const openModal = () => modal?.current?.toggle()

    const renderGroupButtons = () => (
        <Row className="p-2 pl-3">
            {!!step && (
                <Button
                    className="mr-2"
                    color="secondary"
                    onClick={handleGoBack}
                >
                    <FaArrowLeft />
                    <Label className="ml-1 h6 m-0">Go up</Label>
                </Button>
            )}
            {targetMedia && !targetMedia.isFolder && (
                <Button
                    className="mr-2"
                    color="success"
                    onClick={handleDownloadMedia}
                >
                    <FaDownload />
                    <Label className="ml-1 h6 m-0">Download</Label>
                </Button>
            )}
            {targetMedia && (
                <Button
                    className="mr-2"
                    color="danger"
                    onClick={handleDeleteMediaOrFolder}
                >
                    <FaTrash />
                    <Label className="ml-1 h6 m-0">Delete</Label>
                </Button>
            )}
            {targetMedia && !targetMedia.isFolder && (
                <Button className="mr-2" color="primary" onClick={openModal}>
                    <FaEdit />
                    <Label className="ml-1 h6 m-0">Rename</Label>
                </Button>
            )}
            {!targetMedia && (
                <Button className="mr-2" color="success" onClick={openModal}>
                    <FaFolderPlus />
                    <Label className="ml-1 h6 m-0">New folder</Label>
                </Button>
            )}
            {!targetMedia && (
                <Button
                    className="mr-2"
                    color="info"
                    onClick={() => inputFile?.current?.click()}
                >
                    <FaFileUpload />
                    <Label className="ml-1 h6 m-0">Upload</Label>
                </Button>
            )}
            {breadcumb && (
                <Col className="h-100 d-flex align-items-center">
                    {breadcumb}
                </Col>
            )}
        </Row>
    )

    return (
        <div>
            {renderGroupButtons()}
            <ActionModal
                ref={modal}
                targetMedia={targetMedia}
                onConfirm={async (values: any) => {
                    if (values.name != "") {
                        if (targetMedia && !targetMedia.isFolder) {
                            openModal()
                            await handleRenameFile(values.name)
                        } else {
                            openModal()
                            await handleCreateFolder(currentPrefix, values.name)
                        }
                    } else {
                        showErrorAlert("Name can't be empty")
                    }
                }}
            />

            <input
                type="file"
                id="customMediaUpload"
                ref={inputFile}
                style={{ display: "none" }}
                onChange={async (event: any) => {
                    const file = event?.target?.files[0]
                    if (file) {
                        await handleSelectFile({ key: currentPrefix, file })
                    }
                }}
            />
        </div>
    )
}

export default React.memo(MediaNav)
