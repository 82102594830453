import { createAction } from "@reduxjs/toolkit"

import { Feedback } from "../../types/Feedback"

interface FeedbackList {
  items: Feedback[]
  totalItems: number
}

export const setFeedbackList = createAction<FeedbackList>(
  "feedbacks/setFeedbackList"
)
