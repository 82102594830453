import { LegalPage } from "../containers/LegalPages/constants/type";
import { api } from "./api";

const LEGAL_PAGE_URL = "/api/legalPage";

export const get = () =>
  api.get(LEGAL_PAGE_URL);

export const getById = (id: number) =>
  api.get(`${LEGAL_PAGE_URL}/${id}`);

export const create = (data: LegalPage) =>
  api.post(LEGAL_PAGE_URL, data);

export const update = (data: LegalPage) =>
  api.put(`${LEGAL_PAGE_URL}/${data.id}`, data);

export const remove = (id: number) =>
  api.delete(`${LEGAL_PAGE_URL}/${id}`);
