import { IGuide } from "../containers/Guide/constants/type";
import { api, apiUpload } from "./api";

const FILE_URL = `/api/file`;
const CUSTOM_KEY = `${FILE_URL}/custom-key`;
const GUIDE_URL = "/api/guide";

export const customKeyUploadGuide = (formData: FormData) =>
  apiUpload.post(CUSTOM_KEY, formData);

export const get = () => api.get(GUIDE_URL);

export const create = (model: IGuide[]) => api.post(GUIDE_URL, model);