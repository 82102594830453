import React, { FC, useRef } from "react"
import { Table, Row, Col } from "reactstrap"
import { FaRegEye } from "react-icons/fa"

import CustomPagination from "../../../components/Pagination/CustomPagination"
import SearchBox from "../../../components/Inputs/SearchBox"
import FeedbackDetailModal from "../components/FeedbackDetailModal"

import useFeedbackList from "../hooks/useFeedbackList"
import utcToLocalTime from "../../../utils/utcToLocalTime"
import { MINUTE_DATE_FORMAT } from "../../../utils/constants"
import { Feedback } from "../../../types/Feedback"

const ICON_SIZE = 20
const FeedbackList: FC = () => {
  const modal = useRef<any>()
  const {
    feedbackList,
    totalItems,
    filter,
    currentFeedback,
    changeFilter,
    setCurrentFeedback,
  } = useFeedbackList()

  const onCancel = () => {
    setCurrentFeedback(null)
  }

  const openModal = (feedback: Feedback) => {
    setCurrentFeedback(feedback)
    modal?.current?.toggle()
  }

  return (
    <div className="animated fadeIn">
      <FeedbackDetailModal
        ref={modal}
        feedback={currentFeedback}
        onCancel={onCancel}
      />
      <h4>Feedback list</h4>
      <Row className="mb-2">
        <Col md={8}>
          <SearchBox
            initValue={filter.search}
            onSearch={(string) => changeFilter({ search: string })}
          />
        </Col>
        <Col md={4} className="d-flex justify-content-end align-items-center">
          <span className="font-weight-bold">
            Total feedbacks: {totalItems}
          </span>
        </Col>
      </Row>
      <Table responsive bordered striped hover size="sm" className="mb-2">
        <thead>
          <tr>
            <th>Time</th>
            <th>User email</th>
            <th>Content</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {feedbackList?.map((feedback) => (
            <tr key={feedback?.id}>
              <td style={{width: "150"}}>
                {utcToLocalTime(feedback.createdAt, MINUTE_DATE_FORMAT)}
              </td>
              <td>{feedback.userEmail}</td>
              <td>{feedback.content}</td>
              <td className="text-center">
                <FaRegEye
                  size={ICON_SIZE}
                  className="cursor-pointer hover-opacity"
                  onClick={() => openModal(feedback)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="text-right">
        <CustomPagination
          page={filter.page}
          limit={filter.limit}
          totalItems={totalItems}
          changePage={(page: number) => changeFilter({ page })}
          changeLimit={(limit: number) => changeFilter({ limit })}
        />
      </div>
    </div>
  )
}

export default FeedbackList
