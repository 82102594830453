import { useCallback, useEffect, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"

import { setLoading, setAlert, setModal } from "../../../redux/commons/action"
import { District } from "../../../types/District"

import { getById, create, update } from "../../../services/districtService"

const initValue: District = {
  name: "",
  address: "",
  subDomain: "",
  connectionString: "",
  firstName: "",
  lastName: "",
  email: "",
  pusherCluster: "",
  pusherAppId: "",
  pusherKey: "",
  pusherSecret: "",
  googleRecaptchaId: "",
  googleRecaptchaSecretId: ""
}

const DISTRICT_LIST_URL = "/districts"

const useDistrictDetail = () => {
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const history = useHistory()
  const [districtDetail, setDistrictDetail] = useState<District>(initValue)

  const getData = useCallback(async () => {
    if (!id) return
    dispatch(setLoading(true))

    try {
      const res = await getById(parseInt(id))
      setDistrictDetail(res.data)
    } catch (err) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data || err.message,
        })
      )
    }

    dispatch(setLoading(false))
  }, [id])

  const confirm = useCallback(
    async (data: District) => {
      const next = !!id ? update : create
      dispatch(setLoading(true))
      try {
        await next(data)
        dispatch(
          setAlert({
            type: "success",
            message: `${!!id ? "Update" : "Create"} district successfully`,
          })
        )
        backToList()
      } catch (err) {
        dispatch(
          setAlert({
            type: "danger",
            message: err.response?.data || err.message,
          })
        )
      }
      dispatch(setLoading(false))
    },
    [id]
  )

  const backToList = useCallback(() => {
    history.push(DISTRICT_LIST_URL)
  }, [])

  useEffect(() => {
    getData()
  }, [])

  return {
    id,
    districtDetail,
    confirm,
    backToList,
  }
}

export default useDistrictDetail
