import React, { FC, useRef } from "react";
import { Col, Row, Table } from "reactstrap";
import SearchBoxContainer from "../../../components/Inputs/SearchBoxContainer";
import { AiFillEdit } from "react-icons/ai";
import DeleteButtonIcon from "../../../components/Buttons/DeleteButtonIcon";
import CustomPagination from "../../../components/Pagination/CustomPagination";
import CleverModal from "../components/CleverModal";
import useClever from "../hooks/useClever";
import { ClassLinkDomain } from "../../../types/ClassLinkDomain";

const CleverDomainList: FC = () => {
  const {
    data,
    totalItems,
    filter,
    changeFilter,
    removeData,
    getData,
    id,
    cleverDomainDetail,
    reset,
    openModal,
    onConfirm,
    modal,
  } = useClever();

  return (
    <div className="animated fadeIn">
      <CleverModal
        ref={modal}
        header={!!id ? "Edit" : "Create"}
        confirmText={!!id ? "Update" : "Create"}
        onConfirm={onConfirm}
        onCancel={reset}
        data={cleverDomainDetail}
      />
      <h4>Clever Domain</h4>
      <Row className="mb-2">
        <Col md={8}>
          <SearchBoxContainer
            text="Add clever domain"
            onClick={() => openModal()}
            initValue={filter.search}
            onSearch={(string) => changeFilter({ search: string })}
          />
        </Col>
        <Col md={4} className="d-flex justify-content-end align-items-center">
          <span className="font-weight-bold">
            Total clever domain: {totalItems}
          </span>
        </Col>
      </Row>
      <Table responsive bordered striped hover size="sm" className="mb-2">
        <thead>
          <tr>
            <th>DistrictId</th>
            <th>District Name</th>
            <th>Email substrings</th>
            <th>SubDomain</th>
            <th className="text-center" style={{ width: "100px" }}></th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 &&
            data.map((clever: ClassLinkDomain) => (
              <tr key={clever.id}>
                <td>{clever.tenantId}</td>
                <td>{clever.name}</td>
                <td>{clever.email}</td>
                <td>{clever.subDomain}</td>
                <td className="text-center">
                  <AiFillEdit
                    className="mr-2 cursor-pointer hover-opacity"
                    onClick={() => openModal(clever.id)}
                  />
                  <DeleteButtonIcon
                    onClick={() => {
                      removeData(Number(clever.id));
                    }}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div className="text-right">
        <CustomPagination
          page={filter.page}
          limit={filter.limit}
          totalItems={totalItems}
          changePage={(page: number) => changeFilter({ page })}
          changeLimit={(limit: number) => changeFilter({ limit })}
        />
      </div>
    </div>
  );
};

export default CleverDomainList;
