import React, { FC, useRef } from "react";
import useClassLinkDomainList from "../hooks/useClassLinkDomainList";
import { Col, Row, Table } from "reactstrap";
import SearchBoxContainer from "../../../components/Inputs/SearchBoxContainer";
import { ClassLinkDomain } from "../../../types/ClassLinkDomain";
import { AiFillEdit } from "react-icons/ai";
import DeleteButtonIcon from "../../../components/Buttons/DeleteButtonIcon";
import CustomPagination from "../../../components/Pagination/CustomPagination";
import useClassLinkDomainDetail from "../hooks/useClassLinkDomainDetail";
import ClassLinkDomainDetailModal from "../components/ClassLinkDomainDetailModal";

const ClassLinkDomainList: FC = () => {
  const modal = useRef<any>()

  const {
    data,
    totalItems,
    filter,
    changeFilter,
    removeData,
    getData
  } = useClassLinkDomainList()

  const {
    id,
    setId,
    confirmData,
    classLinkDomainDetail,
    reset
  } = useClassLinkDomainDetail()

  const openModal = (id?: number) => {
    !!id ? setId(id) : setId(0)
    modal && modal.current && modal.current.toggle()
  }

  const onConfirm = (values: any) => {
    confirmData(values, () => {
      modal && modal.current && modal.current.toggle()
      getData()
    })
  }

  return (
    <div className="animated fadeIn">
      <ClassLinkDomainDetailModal
          ref={modal}
          header={!!id ? "Edit" : "Create"}
          confirmText={!!id ? "Update" : "Create"}
          onConfirm={onConfirm}
          onCancel={reset}
          data={classLinkDomainDetail}
        />
      <h4>Classlink Domain</h4>
      <Row className="mb-2">

        <Col md={8}>
          <SearchBoxContainer
            text="Add classlink domain"
            onClick={() => openModal()}
            initValue={filter.search}
            onSearch={(string) => changeFilter({ search: string })}
          />
        </Col>
        <Col md={4} className="d-flex justify-content-end align-items-center">
          <span className="font-weight-bold">
            Total classlink domain: {totalItems}
          </span>
        </Col>
      </Row>
      <Table responsive bordered striped hover size="sm" className="mb-2">
        <thead>
          <tr>
            <th>TenantId</th>
            <th>OrgId</th>
            <th>Email</th>
            <th>Tenant Name</th>
            <th>SubDomain</th>
            <th className="text-center" style={{ width: "100px" }}></th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 && data.map((classLinkDomain: ClassLinkDomain) => (
            <tr key={classLinkDomain.id}>
              <td>{classLinkDomain.tenantId}</td>
              <td>{classLinkDomain.orgId}</td>
              <td>{classLinkDomain.email}</td>
              <td>{classLinkDomain.name}</td>
              <td>{classLinkDomain.subDomain}</td>
              <td className="text-center">
                <AiFillEdit
                  className="mr-2 cursor-pointer hover-opacity"
                  onClick={() => openModal(classLinkDomain.id)}
                />
                <DeleteButtonIcon
                  onClick={() => {
                    removeData(Number(classLinkDomain.id))
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="text-right">
        <CustomPagination
          page={filter.page}
          limit={filter.limit}
          totalItems={totalItems}
          changePage={(page: number) => changeFilter({ page })}
          changeLimit={(limit: number) => changeFilter({ limit })}
        />
      </div>
    </div>
  )
}

export default ClassLinkDomainList