import { GuideType, IGuide } from "./type";

export const initGuide: IGuide[] = [
    {
        type: GuideType.CheckIn,
        source: ""
    },
    {
        type: GuideType.ClassReflection,
        source: ""
    },
    {
        type: GuideType.Conference,
        source: ""
    },
    {
        type: GuideType.Dashboard,
        source: ""
    },
    {
        type: GuideType.Plp,
        source: ""
    },
    {
        type: GuideType.Survey,
        source: ""
    }
];
