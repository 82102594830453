import React, {FC} from 'react'
import {Card,CardImg, CardBody, CardTitle} from 'reactstrap';
import {S3Object} from '../constants/gallery.types'
import {getMediaThumbnail} from '../extensions/helpers'

interface MediaInterface {
  media: S3Object,
  isTarget: boolean
}

const Media: FC<MediaInterface> = ({media, isTarget}) => {
  const thumbnail = getMediaThumbnail(media)
  return (
      <Card className={`${isTarget ? "media-image-selected" : ''} border-0 `}>
        <CardImg
          className="media-image p-1"
          top
          width="90%"
          src={thumbnail}
          alt={media.name}
        />
        <CardBody className="p-1" style={{height: 45}}>
          <CardTitle tag="h6" className="media-title">{media.name}</CardTitle>
        </CardBody>
      </Card>
  )
}

export default React.memo(Media)
