import React, { forwardRef } from "react"
import CommonModal from "../../../components/Modals/CommonModal"
import RequiredLabel from "../../../components/Labels/RequiredLabel"
import ErrorHandler from "../../../components/Alerts/ErrorHandler"
import UploadFileButton from "../../../components/Buttons/UploadFileButton"
import * as yup from "yup"
import { Formik } from "formik"
import { Col, Input, Row, FormGroup, Label } from "reactstrap"

const DEFAULT_IMAGE_URL = "https://tleliteracy.com/wp-content/uploads/2017/02/default-avatar.png"
const schema = yup.object({
  name: yup.string().trim().required("Name is required"),
  code: yup.string().trim().required("Code is required"),
})

const LanguageDetailModal = (props: any, ref: any) => {
  const { header, confirmText, onConfirm, adminTipDetail, onCancel, file, setFile, setEditedTrue } = props
  return (
    <Formik
      initialValues={adminTipDetail}
      enableReinitialize
      validationSchema={schema}
      onSubmit={(values: AnalyserNode, actions: any) => {
        onConfirm(values, () => {
          onCancel()
        })
        actions.resetForm()
      }}
    >
      {(formikProps: any) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue
        } = formikProps

        return (
          <CommonModal
            header={header + " Language"}
            confirmText={confirmText}
            onConfirm={handleSubmit}
            onCancel={() => {
              onCancel()
              resetForm()
            }}
            ref={ref}
          >
            <Row>
              <Col md={12} className="mb-2">
                  <RequiredLabel text="Logo" />
                  <div className="mb-3">
                      <UploadFileButton
                        setFile={setFile}
                        fileName={
                            values.logo
                        }
                        onAddImage={setEditedTrue}
                        canDelete
                        onDelete={() => {
                            setFile(null)
                            setFieldValue(
                                "logo",
                                ""
                            )
                            setEditedTrue()
                        }}
                      />
                      {!file &&
                          !values.logo && (
                              <img
                                src={DEFAULT_IMAGE_URL}
                                style={{ maxWidth: "100%", width: "50%" }}
                              />
                        )}
                  </div>
              </Col>
              <Col md={12} className="mb-2">
                  <RequiredLabel text="Name" />
                  <Input
                    name="name"
                    placeholder="Enter Language..."
                    type="text"
                    value={values.name}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={handleBlur("name")}
                  />
                  {touched.name && errors.name && (
                    <ErrorHandler text={errors.name as string} />
                  )}
                </Col>
                <Col md={12} className="mb-2">
                  <RequiredLabel text="Code" />
                  <Input
                    name="code"
                    placeholder="Enter code..."
                    type="text"
                    value={values.code}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={handleBlur("code")}
                  />
                  {touched.code && errors.code && (
                    <ErrorHandler text={errors.code as string} />
                  )}
                </Col>
                <Col md={12} className="mb-2">
                    <FormGroup check>
                      <Input 
                        type="checkbox" 
                        checked={values.isActive} 
                        onChange={(e: any) => {
                          setFieldValue(
                            "isActive",
                            !values.isActive
                          )
                        }}  
                        onBlur={handleBlur("isActive")}
                      />
                      {' '}
                      <Label check>
                        Is Active
                      </Label>
                    </FormGroup>
                </Col>
                <Col md={12} className="mb-2">
                    <FormGroup check>
                      <Input 
                        type="checkbox" 
                        checked={values.rtl} 
                        onChange={(e: any) => {
                          setFieldValue(
                            "rtl",
                            !values.rtl
                          )
                        }}  
                        onBlur={handleBlur("rtl")}
                      />
                      {' '}
                      <Label check>
                          Rtl
                      </Label>
                    </FormGroup>
                </Col>
            </Row>
          </CommonModal>
        )
      }}
    </Formik>
  )
}

export default forwardRef(LanguageDetailModal)