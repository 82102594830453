import * as yup from "yup"

const schema = yup.object({
  name: yup.string().required("District name is required"),
  address: yup.string().nullable(),
  subDomain: yup.string().required("Sub domain is required").nullable(),
  firstName: yup.string().required("First Name is required").nullable(),
  lastName: yup.string().required("Last Name is required").nullable(),
  email: yup.string().required("Email is required").nullable(),
  connectionString: yup
    .string()
    .required("Connection string is required")
    .nullable(),
})

export default schema
