import { createAction } from "@reduxjs/toolkit"

import { PricingPlan } from "../../types/PricingPlan"

interface PricingPlanList {
  items: PricingPlan[]
  totalItems: number
}

export const setPricingPlanList = createAction<PricingPlanList>(
  "pricingPlans/setPricingPlanList"
)
