import { useState, useEffect, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { Filter } from "../../../types/Filter"
import { Feedback } from "../../../types/Feedback"
import { DEFAULT_FILTER } from "../../../utils/constants"
import { setLoading, setAlert } from "../../../redux/commons/action"
import { setFeedbackList } from "../../../redux/feedbacks/action"

import { get } from "../../../services/feedbackService"

const useFeedbackList = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [filter, setFilter] = useState<Filter>(DEFAULT_FILTER)
  const [currentFeedback, setCurrentFeedback] = useState<Feedback | null>(null)
  const feedbackList: Feedback[] = useSelector(
    (state: any) => state.feedbacks.feedbackList
  )
  const totalItems: number = useSelector(
    (state: any) => state.feedbacks.totalItems
  )

  const changeFilter = (valueObject: any) =>
    setFilter({
      ...filter,
      ...valueObject,
    })

  const getData = useCallback(async () => {
    dispatch(setLoading(true))

    try {
      const res = await get(filter)
      const { totalItems, items } = res.data
      dispatch(setFeedbackList({ items, totalItems }))
    } catch (err) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data || err.message,
        })
      )
    }

    dispatch(setLoading(false))
  }, [filter])

  useEffect(() => {
    getData()
  }, [filter])

  return {
    feedbackList,
    totalItems,
    filter,
    currentFeedback,
    setCurrentFeedback,
    changeFilter,
  }
}

export default useFeedbackList
