import React, { FC } from "react"
import {
    Card,
    CardImg,
    CardTitle,
    CardHeader,
    CardBody,
    CardText
} from "reactstrap"
import getFileUrl from "../../../utils/getFileUrl"
import { MediaType, S3Object } from "../constants/gallery.types"
import { getTypeByExtension } from "../extensions/helpers"
interface MediaDetailInterface {
    targetMedia: S3Object
}

const MediaDetail: FC<MediaDetailInterface> = ({ targetMedia }) => {
    if (!targetMedia || targetMedia?.isFolder) return <div />
    const type = getTypeByExtension(targetMedia.extension)
    const url = getFileUrl(targetMedia.key || "")

    return (
        <Card className="position-sticky">
            <CardHeader>{targetMedia?.name || "Media name"}</CardHeader>
            {type === MediaType.IMAGE && (
                <CardImg className="p-2" src={url} loading="lazy" />
            )}
            {type === MediaType.VIDEO && (
                <div className="p-2">
                    <video
                        id={targetMedia.key}
                        width="100%"
                        height="100%"
                        controls
                    >
                        <source src={url} />
                    </video>
                </div>
            )}
            {type === MediaType.AUDIO && (
                <div className="p-2">
                    <audio controls className="w-100">
                        <source src={url}/>
                    </audio>
                </div>
            )}
            <CardBody>
                <CardTitle tag="h6">General Info</CardTitle>
                <CardText>
                    File extension: {targetMedia.extension?.toUpperCase()}
                </CardText>
            </CardBody>
        </Card>
    )
}

export default React.memo(MediaDetail)
