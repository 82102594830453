import React, { forwardRef } from "react"

import CommonModal from "../../../components/Modals/CommonModal"
import utcToLocalTime from "../../../utils/utcToLocalTime"
import getFileUrl from "../../../utils/getFileUrl"
import { MINUTE_DATE_FORMAT } from "../../../utils/constants"

const header = "Feedback detail"

const FeedbackDetailModal = (props: any, ref: any) => {
  const { feedback, onCancel } = props

  return (
    <CommonModal noFooter header={header} onCancel={onCancel} ref={ref}>
      <p className="mb-0 text-sm">
        {utcToLocalTime(feedback?.createdAt, MINUTE_DATE_FORMAT)}
      </p>
      <p className="font-weight-bold mb-2">{feedback?.userEmail}</p>
      <p>{feedback?.content}</p>
      {feedback?.imageUrl && (
        <img
          src={getFileUrl(feedback.imageUrl)}
          alt="feedback-attachment"
          style={{ width: "100%", height: "100%" }}
        />
      )}
    </CommonModal>
  )
}

export default forwardRef(FeedbackDetailModal)
