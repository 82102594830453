import { createReducer } from "@reduxjs/toolkit"

import { setFeedbackList } from "./action"

const initialState: any = {
  feedbackList: [],
  totalItems: 0,
}

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(setFeedbackList, (state, action) => {
    state.feedbackList = action.payload.items
    state.totalItems = action.payload.totalItems
  })
})

export default reducer
