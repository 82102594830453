const Images = {
  LOGO: "images/logo.png",
  LOGO_MINIMIZED: "images/logo-minimized.png",
  LOGO_HEADER: "images/logo-header.png",
  DOCS_IMAGE: "images/docs.png",
  AUDIO_IMAGE: "images/audio.png",
  PHOTO_IMAGE: "images/image.png",
  VIDEO_IMAGE: "images/video.png",
  FOLDER_IMAGE: "images/folder.png",
  UNKNOW_IMAGE: "images/unknow.png"
}

export default Images
