import React, { FC, forwardRef } from "react";
import CommonModal from "../../../components/Modals/CommonModal";
import RequiredLabel from "../../../components/Labels/RequiredLabel";
import ErrorHandler from "../../../components/Alerts/ErrorHandler";

import * as yup from "yup";
import { Formik } from "formik";
import { Col, Input, Row } from "reactstrap";
import { ClassLinkDomain } from "../../../types/ClassLinkDomain";
import SchoolSelector from "./SchoolSelector";

const schema = yup.object({
  tenantId: yup.string().trim().required("TenantId is required"),
  orgId: yup.string().trim().required("OrgId is required"),
  name: yup.string().trim().required("Tenant Name is required"),
  schoolId: yup.number().min(1, "School is required"),
  email: yup.string().trim().required("Email Extension is required"),
})

interface Props {
  header: string
  confirmText: string
  onConfirm: (values: ClassLinkDomain) => void
  data: ClassLinkDomain
  onCancel: () => void
}

const ClassLinkDomainDetailModal = (
  { header, confirmText, onConfirm, data, onCancel }: Props,
  ref: any
) => {
  return (
    <Formik
      initialValues={data}
      enableReinitialize
      validationSchema={schema}
      onSubmit={(values: ClassLinkDomain) => {
        onConfirm(values);
      }}
    >
      {(formikProps: any) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue
        } = formikProps;
        return (
          <CommonModal
            header={header}
            confirmText={confirmText}
            onConfirm={handleSubmit}
            onCancel={() => {
              onCancel();
              resetForm();
            }}
            ref={ref}
          >
            <Row>
              <Col md={12} className="mb-2">
                <RequiredLabel text="TenantId" />
                <Input
                  name="tenantId"
                  placeholder="Enter tenantId..."
                  type="text"
                  value={values.tenantId}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur("TenantId")}
                />
                {touched.tenantId && errors.tenantId && (
                  <ErrorHandler text={errors.tenantId as string} />
                )}
              </Col>
              <Col md={12} className="mb-2">
                <RequiredLabel text="OrgId" />
                <Input
                  name="orgId"
                  placeholder="Enter OrgId..."
                  type="text"
                  value={values.orgId}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur("orgId")}
                />
                {touched.orgId && errors.orgId && (
                  <ErrorHandler text={errors.orgId as string} />
                )}
              </Col>
              <Col md={12} className="mb-2">
                <RequiredLabel text="Email Extension" />
                <Input
                  name="email"
                  placeholder="Enter Email..."
                  type="text"
                  value={values.email}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur("email")}
                />
                {touched.email && errors.email && (
                  <ErrorHandler text={errors.email as string} />
                )}
              </Col>
              <Col md={12} className="mb-2">
                <RequiredLabel text="Tenant Name" />
                <Input
                  name="name"
                  placeholder="Enter name..."
                  type="text"
                  value={values.name}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur("name")}
                />
                {touched.name && errors.name && (
                  <ErrorHandler text={errors.name as string} />
                )}
              </Col>
              <Col md={12} className="mb-2">
                <RequiredLabel text="Select School" />
                <SchoolSelector
                  schoolId={values.schoolId}
                  onChange={(e: any) => {
                    setFieldValue("schoolId", (e && e.value))
                  }}
                />
                {touched.schoolId && errors.schoolId && (
                  <ErrorHandler text={errors.schoolId as string} />
                )}
              </Col>
            </Row>
          </CommonModal>
        );
      }}
    </Formik>
  );
};

export default forwardRef(ClassLinkDomainDetailModal);