export type S3Object = {
  name?: string
  key?: string
  extension?: string
  isFolder?: boolean
}

export enum MediaType {
  IMAGE,
  VIDEO,
  AUDIO,
  DOC,
  UNKNOW
}

export type FilterType = {
  searchString?: string,
  types?: MediaType[],
  prefix?: string,
  fromDate?: number,
  toDate?: number,
}
