import { useDispatch } from "react-redux";
import { AdminTip } from "../../../types/AdminTip";
import { useCallback, useEffect, useState } from "react";
import { setAlert, setLoading } from "../../../redux/commons/action";
import { create, getById, getLanguages, update } from "../../../services/adminTipService";
import { ILanguage } from "../ultils/type";

const initValue: AdminTip = {
  text: ""
}

const useAdminTipDetail = () => {
  const dispatch = useDispatch()

  const [id, setId] = useState(0)
  const [adminTipDetail, setAdminTipDetail] = useState<AdminTip>(initValue)
  const [listLanguage, setListLanguage] = useState<ILanguage[]>([]);
  const [activeLanguage, setActiveLanguage] = useState<string>("en-US");

  const onChangeLanguage = (code: string) => {
    setActiveLanguage(code)
}

  useEffect(() => {
    !!id ? getData(activeLanguage) : setAdminTipDetail(initValue)
  }, [id, activeLanguage])

  const reset = () => {
    setAdminTipDetail(initValue)
    setId(0)
  }

  const getData = useCallback(async (lang) => {
    dispatch(setLoading(true))

    try {
      let objLang = {
        lang: lang
    };
      const res = await getById(id, objLang)
      setAdminTipDetail(res.data)
    } catch (err: any) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data?.title || err.message,
        })
      )
    }
    dispatch(setLoading(false))
  }, [id, activeLanguage])

  const confirmData = useCallback(
    async (values: any, cb: any) => {
      dispatch(setLoading(true))
      try {
        values.lang = activeLanguage;
        !!id ? await update(values) : await create(values)
        
        dispatch(
          setAlert({
            type: "success",
            message: `${!!id ? "Update" : "Create"} admin tip successfully`,
          })
        )

        cb && cb()
        !!id && setId(0)
      } catch (err: any) {
        dispatch(
          setAlert({
            type: "danger",
            message: err.response?.data || err.message,
          })
        )
      }
      dispatch(setLoading(false))
    }, [id, activeLanguage]
  )

  const getLanguageSystems = async () => {
      dispatch(setLoading(true))
      try {
          const res = await getLanguages({
            limit: 10000,
            page: 1,
            search: ""
          })
          setListLanguage(res.data.items)
      } catch (err: any) {
          dispatch(
              setAlert({
                  type: "danger",
                  message: err.response?.data?.title || "Get question failed"
              })
          )
      }
      dispatch(setLoading(false))
  }

  useEffect(() => {
    getLanguageSystems()
  }, [])

  return {
    id, 
    setId,
    adminTipDetail,
    confirmData,
    initValue,
    reset,
    listLanguage,
    activeLanguage,
    onChangeLanguage
  }
}

export default useAdminTipDetail