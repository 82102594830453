import React, { FC } from "react"
import { AiFillEdit } from "react-icons/ai"
import { Link } from "react-router-dom"
import { Table, Row, Col } from "reactstrap"

import CustomPagination from "../../../components/Pagination/CustomPagination"
import DeleteButtonIcon from "../../../components/Buttons/DeleteButtonIcon"
import SearchBoxContainer from "../../../components/Inputs/SearchBoxContainer"

import { District } from "../../../types/District"

import useDistrictList from "../hooks/useDistrictList"

const PricingPlanList: FC = () => {
  const {
    DISTRICT_DETAIL_URL,
    districtList,
    totalItems,
    filter,
    changeFilter,
    removeData,
    goToDetail,
  } = useDistrictList()

  return (
    <div className="animated fadeIn">
      <h4>District list</h4>
      <Row className="mb-2">
        <Col md={8}>
          <SearchBoxContainer
            text="Add district"
            onClick={() => {
              goToDetail()
            }}
            initValue={filter.search}
            onSearch={(string) => changeFilter({ search: string })}
          />
        </Col>
        <Col md={4} className="d-flex justify-content-end align-items-center">
          <span className="font-weight-bold">Total district: {totalItems}</span>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={12}>
          <Table responsive bordered striped hover size="sm" className="mb-2">
            <thead>
              <tr>
                <th>Name</th>
                <th>Address</th>
                <th>Sub domain</th>
                <th>Connection string</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {districtList?.map((district: District) => (
                <tr key={district?.id}>
                  <td>
                    <Link to={`${DISTRICT_DETAIL_URL}/${district.id}`}>
                      {district.name}
                    </Link>
                  </td>
                  <td className="align-middle">{district.address}</td>
                  <td className="align-middle">{district.subDomain}</td>
                  <td className="align-middle">{district.connectionString}</td>
                  <td className="text-center align-middle">
                    <DeleteButtonIcon
                      onClick={() => {
                        removeData(Number(district.id), district.name)
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="text-right">
            <CustomPagination
              page={filter.page}
              limit={filter.limit}
              totalItems={totalItems}
              changePage={(page: number) => changeFilter({ page })}
              changeLimit={(limit: number) => changeFilter({ limit })}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default PricingPlanList
