import React, { FC, useRef } from "react"
import { AiFillEdit } from "react-icons/ai"
import { Table, Row, Col } from "reactstrap"

import CustomPagination from "../../../components/Pagination/CustomPagination"
import DeleteButtonIcon from "../../../components/Buttons/DeleteButtonIcon"
import SearchBoxContainer from "../../../components/Inputs/SearchBoxContainer"

import { PricingPlan } from "../../../types/PricingPlan"

import PricingPlanDetailModal from "./PricingPlanDetailModal"
import usePricingPlanList from "../hooks/usePricingPlanList"
import usePricingPlanDetail from "../hooks/usePricingPlanDetail"

const PricingPlanList: FC = () => {
  const modal = useRef<any>()

  const {
    pricingPlanList,
    totalItems,
    filter,
    changeFilter,
    removeData,
    getData,
  } = usePricingPlanList()

  const { id, setId, confirmData, pricingPlanDetail, reset } =
    usePricingPlanDetail()

  const openModal = (badgeId?: number) => {
    !!badgeId ? setId(badgeId) : setId(0)
    modal && modal.current && modal.current.toggle()
  }

  const onConfirm = (values: any) => {
    confirmData(values, () => {
      modal && modal.current && modal.current.toggle()
      getData()
    })
  }

  const onCancel = () => reset()

  return (
    <div className="animated fadeIn">
      <PricingPlanDetailModal
        ref={modal}
        header={!!id ? "Edit pricing plan" : "Create pricing plan"}
        confirmText={!!id ? "Update" : "Create"}
        onConfirm={onConfirm}
        onCancel={onCancel}
        pricingPlanDetail={pricingPlanDetail}
      />
      <h4>Pricing plan list</h4>
      <Row className="mb-2">
        <Col md={8}>
          <SearchBoxContainer
            text="Add pricing plan"
            onClick={() => openModal()}
            initValue={filter.search}
            onSearch={(string) => changeFilter({ search: string })}
          />
        </Col>
        <Col md={4} className="d-flex justify-content-end align-items-center">
          <span className="font-weight-bold">
            Total pricing plan: {totalItems}
          </span>
        </Col>
      </Row>
      <Table responsive bordered striped hover size="sm" className="mb-2">
        <thead>
          <tr>
            <th>Name</th>
            <th>Number Of Admins</th>
            <th>Number Of Assistants</th>
            <th>Number Of Students</th>
            <th>Number Of Teachers</th>
            <th className="text-center"></th>
          </tr>
        </thead>
        <tbody>
          {pricingPlanList?.map((pricingPlan: PricingPlan) => (
            <tr key={pricingPlan?.id}>
              <td>{pricingPlan.name}</td>
              <td>{pricingPlan.numberOfAdmins}</td>
              <td>{pricingPlan.numberOfAssistants}</td>
              <td>{pricingPlan.numberOfStudents}</td>
              <td>{pricingPlan.numberOfTeachers}</td>
              <td className="text-center">
                <AiFillEdit
                  className="mr-2 cursor-pointer hover-opacity"
                  onClick={() => openModal(pricingPlan.id)}
                />
                <DeleteButtonIcon
                  onClick={() => {
                    removeData(Number(pricingPlan.id), pricingPlan.name)
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="text-right">
        <CustomPagination
          page={filter.page}
          limit={filter.limit}
          totalItems={totalItems}
          changePage={(page: number) => changeFilter({ page })}
          changeLimit={(limit: number) => changeFilter({ limit })}
        />
      </div>
    </div>
  )
}

export default PricingPlanList
