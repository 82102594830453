import React, { FC, useState } from "react"
import { useDispatch } from "react-redux"
import { Form, Input, Label, Button } from "reactstrap"
import { FaTrashAlt } from "react-icons/fa"
import { MdAttachFile } from "react-icons/md"
import getFileUrl from "../../utils/getFileUrl"
import { setAlert } from "../../redux/commons/action"

interface Props {
    fileName?: string
    maxWidth?: string
    maxHeight?: string
    setFile: any
    onAddImage?: any
    canDelete?: boolean
    onDelete?: () => void
    isCheckFeedBack?: boolean
}

const MAX_FILE_SIZE = 20097152

const UploadFileButton: FC<Props> = ({
    fileName,
    maxWidth,
    maxHeight,
    setFile,
    canDelete,
    onDelete,
    isCheckFeedBack,
    onAddImage
}: Props) => {
    const dispatch = useDispatch()
    const [imagePreviewUrl, setImagePreviewUrl] = useState<any>("")

    const imageStyle = {
        maxWidth: maxWidth || "100%",
        maxHeight: maxHeight || "100%"
    }
    const imageSrc = imagePreviewUrl || getFileUrl(fileName as string)

    const handleImageChange = (e: any) => {
        e.preventDefault()

        let reader = new FileReader()
        let newFile = e.target.files[0]

        if (newFile.size > MAX_FILE_SIZE) {
            dispatch(
                setAlert({
                    type: "danger",
                    message: "File can't be larger than 2MB"
                })
            )
            return
        }

        reader.onloadend = () => {
            console.log({ newFile })
            setFile(newFile)
            setImagePreviewUrl(reader.result)
        }

        if (newFile) {
            reader.readAsDataURL(newFile)
        }
        !!onAddImage && onAddImage()
    }

    const imagePreviewContainer =
        imagePreviewUrl || fileName ? (
            <img style={imageStyle} src={imageSrc} alt="image" />
        ) : null

    return (
        <div className="mb-2">
            <Form className="mb-2">
                <Input
                    className="d-none"
                    type="file"
                    id="uploadFile"
                    onChange={handleImageChange}
                    accept="image/*"
                />
                <div className="d-flex align-items-center">
                    <Label
                        htmlFor="uploadFile"
                        role="button"
                        tabIndex={0}
                        className="input-file-label"
                    >
                        {isCheckFeedBack == true ? (
                            <MdAttachFile />
                        ) : (
                            <React.Fragment>
                                <i
                                    className="fa fa-paperclip mr-1"
                                    aria-hidden="true"
                                ></i>
                                {imagePreviewUrl || fileName
                                    ? "Change file"
                                    : "Choose file"}
                            </React.Fragment>
                        )}
                    </Label>

                    {canDelete && imagePreviewContainer && (
                        <Button
                            size="sm"
                            color="danger"
                            className={`delete-avatar-button ml-2 d-flex align-items-center justify-content-center`}
                            onClick={() => {
                                setImagePreviewUrl(null)
                                onDelete && onDelete()
                            }}
                        >
                            <FaTrashAlt />
                        </Button>
                    )}
                </div>
            </Form>
            {imagePreviewContainer}
        </div>
    )
}

export default UploadFileButton
