import React, { FC, useCallback, useEffect, useState } from "react";
import Select from "react-select";
import useSchoolSelector from "../hooks/useSchoolSelector";
import { get } from "../../../services/schoolService";
import CommonSelector from "../../../components/Selectors/CommonSelector";

interface Props {
  schoolId: number;
  onChange: Function;
}

interface SchoolOption {
  value: number
  label: string
}

const SchoolSelector: FC<Props> = ({ schoolId, onChange }: Props) => {
  const [options, setOptions] = useState<SchoolOption[]>([])

  useEffect(() => {
    getData()
  }, [])

  const getData = useCallback(async () => {

    try {
      const res = await get({
        page: 1,
        limit: -1,
        search: ""
      })
      setOptions(
        res.data.items.map((item: any) => ({
          value: item.id,
          label: item.name,
        }))
      )
    } catch (err) {
      console.error(err)
    }
  }, [])

  return (
    <CommonSelector
      defaultValue={schoolId}
      options={options}
      onChange={onChange}
      isClearable
    />
  )
}

export default SchoolSelector;
