import {
  FILTER,
  NOTIFICATION_ALERT,
} from "../containers/NotificationAlerts/constants/types";
import { api } from "./api";

const NOTIFICATION_ALERT_URL = "/api/notification-alerts";

export const get = (filter?: FILTER) =>
  api.get(NOTIFICATION_ALERT_URL, { params: filter });

export const getById = (id: number) =>
  api.get(`${NOTIFICATION_ALERT_URL}/${id}`);

export const create = (data: NOTIFICATION_ALERT) =>
  api.post(NOTIFICATION_ALERT_URL, data);

export const update = (data: NOTIFICATION_ALERT) =>
  api.put(`${NOTIFICATION_ALERT_URL}/${data.id}`, data);

export const remove = (id: number) =>
  api.delete(`${NOTIFICATION_ALERT_URL}/${id}`);
