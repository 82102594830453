export interface IFormGuide {
    title: string,
    inputId: GuideType,
    onSelectFile?: Function,
    inputLink?: string,
    onChangeLink?: Function
}

export interface IGuide {
    type: GuideType
    source: string
}

export enum GuideType
{
    CheckIn,
    Dashboard,
    Plp,
    Conference,
    Survey,
    ClassReflection
}