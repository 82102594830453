export type ClassLinkDomain = {
  id?: number
  tenantId: string
  orgId: string
  name: string
  schoolId: number
  subDomain?: string
  type?: DomainType
  email?: string
}

export enum DomainType
{
    ClassLink,
    Clever
}