import React, { FC } from "react";
import { CNav, CNavItem, CNavLink, CTabContent } from "@coreui/react";
import { LegalPage } from "../constants/type";
import TabPanel from "./TabPanel";
import { useHistory } from "react-router";
import styles from "../styles/legalPageList.module.scss";
import { getUrlRidrect } from "../constants/function";

interface Props {
  data: LegalPage[]
  dataSelected: LegalPage
  setActiveKey?: Function
  activeKey: number
  confirmData: Function
  setEditedTrue: Function
  onBack: Function
}

const CustomTabs: FC<Props> = ({ data, activeKey, confirmData, setEditedTrue, dataSelected, onBack }) => {
  const history = useHistory()
  return (
    <React.Fragment>
      <CNav variant="pills" role="tablist" className="border-bottom px-3 pt-3">
        {
          data &&
          data.length > 0 &&
          data.map((item: LegalPage, index: number) => {
            const url = getUrlRidrect(item, index)
            return <CNavItem key={index}>
              <CNavLink
                href={url}
                active={activeKey === index}
                onClick={(e: any) => {
                  e.preventDefault()
                  activeKey !== index && history.push(url)
                }}
                className={`px-4 ${styles["tab-header"]}`}
              >
                { item.language }
              </CNavLink>
            </CNavItem>
          })
        }
      </CNav>
      <CTabContent>
        {
          data &&
          data.length > 0 &&
          data.map((item: LegalPage, index: number) => (
            <TabPanel
              key={index}
              data={index === activeKey ? dataSelected : item}
              index={index}
              confirmData={confirmData}
              setEditedTrue={setEditedTrue}
              activeKey={activeKey}
              onBack={onBack}
            />
          ))
        }
      </CTabContent>
    </React.Fragment>
  );
};

export default CustomTabs;
