import React, { forwardRef } from "react";
import CommonModal from "../../../components/Modals/CommonModal";
import RequiredLabel from "../../../components/Labels/RequiredLabel";
import ErrorHandler from "../../../components/Alerts/ErrorHandler";

import * as yup from "yup";
import { Formik } from "formik";
import { Col, Input, Label, Row } from "reactstrap";
import SchoolSelector from "./SchoolSelector";
import { ICleverModal } from "../constants/types";
import { ClassLinkDomain } from "../../../types/ClassLinkDomain";

const schema = yup.object({
  name: yup.string().trim().required("Tenant Name is required"),
  email: yup.string().trim().required("Email substring is required"),
  schoolId: yup.number().min(1, "School is required"),
});

const CleverModal = (
  { header, confirmText, onConfirm, data, onCancel }: ICleverModal,
  ref: any
) => {
  return (
    <Formik
      initialValues={data}
      enableReinitialize
      validationSchema={schema}
      onSubmit={(values: ClassLinkDomain) => {
        onConfirm(values);
      }}
    >
      {(formikProps: any) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue,
        } = formikProps;
        return (
          <CommonModal
            header={header}
            confirmText={confirmText}
            onConfirm={handleSubmit}
            onCancel={() => {
              onCancel();
              resetForm();
            }}
            ref={ref}
          >
            <Row>
              <Col md={12} className="mb-2">
                <Label text="DistrictId" />
                <Input
                  name="tenantId"
                  placeholder="Enter districtId..."
                  type="text"
                  value={values.tenantId}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur("TenantId")}
                />
              </Col>

              <Col md={12} className="mb-2">
                <RequiredLabel text="Email substrings" />
                <Input
                  name="email"
                  placeholder="Enter Email Substrings..."
                  type="text"
                  value={values.email}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur("email")}
                />
                {touched.email && errors.email && (
                  <ErrorHandler text={errors.email as string} />
                )}
              </Col>

              <Col md={12} className="mb-2">
                <RequiredLabel text="District Name" />
                <Input
                  name="name"
                  placeholder="Enter name..."
                  type="text"
                  value={values.name}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur("name")}
                />
                {touched.name && errors.name && (
                  <ErrorHandler text={errors.name as string} />
                )}
              </Col>

              <Col md={12} className="mb-2">
                <RequiredLabel text="Select School" />
                <SchoolSelector
                  schoolId={values.schoolId}
                  onChange={(e: any) => {
                    setFieldValue("schoolId", e && e.value);
                  }}
                />
                {touched.schoolId && errors.schoolId && (
                  <ErrorHandler text={errors.schoolId as string} />
                )}
              </Col>
            </Row>
          </CommonModal>
        );
      }}
    </Formik>
  );
};

export default forwardRef(CleverModal);
