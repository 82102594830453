import React, { FC, useState, useEffect, useCallback } from "react"

import CommonSelector from "../../../components/Selectors/CommonSelector"
import { get } from "../../../services/districtService"

interface Props {
  districtId?: number | null
  onChange: Function
}

interface DistrictOption {
  value: number
  label: string
}

const DistrictSelector: FC<Props> = ({ districtId, onChange }: Props) => {
  const [options, setOptions] = useState<DistrictOption[]>([])

  useEffect(() => {
    getData()
  }, [])

  const getData = useCallback(async () => {
    try {
      const res = await get()
      setOptions(
        res.data.items.map((item: any) => ({
          value: item.id,
          label: item.name,
        }))
      )
    } catch (err) {
      console.error(err)
    }
  }, [])

  return (
    <CommonSelector
      defaultValue={districtId}
      options={options}
      onChange={onChange}
      isClearable
    />
  )
}

export default DistrictSelector
