import React, { FC } from "react";
import Select from "react-select";
import useSchoolSelector from "../hooks/useSchoolSelector";

interface Props {
  schoolIds?: Array<number>;
  onChange: Function;
  isSingle?: boolean;
}

const SchoolSelector: FC<Props> = ({
  schoolIds,
  onChange,
  isSingle
}: Props) => {
  const { options, loading, getData } = useSchoolSelector();
  const defaultValue = options.filter((o) => schoolIds?.includes(o.value))
  return (
        <Select
          value={defaultValue}
          options={options}
          onChange={(value) => {
            onChange(value);
          }}
          isClearable
          isMulti={!isSingle}
          placeholder="Select school..."
          maxMenuHeight={200}
          isLoading={loading}
          onInputChange={(newValue: string) => {
            getData(newValue)
          }}
        />
  );
};

export default SchoolSelector;
