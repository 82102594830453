import { createReducer } from "@reduxjs/toolkit"

import { setGallery, setTargetMedia } from "./action"

const initialState: any = {
    mediaList: [],
    totalItems: 0,
    targetMedia: undefined
}

const reducer = createReducer(initialState, builder => {
    builder
        .addCase(setGallery, (state, action) => {
            state.mediaList = action.payload.mediaList
            state.totalItems = action.payload.totalItems
        })
        .addCase(setTargetMedia, (state, action) => {
            state.targetMedia = action.payload.targetMedia
        })
})

export default reducer
