import { api } from "./api"
import { Filter } from "../types/Filter"
import { PricingPlan } from "../types/PricingPlan"

const PRICING_PLAN_URL = "/api/PricingPlan"

export const get = (filter?: Filter) =>
  api.get(PRICING_PLAN_URL, { params: filter })

export const getById = (id: number) => api.get(`${PRICING_PLAN_URL}/${id}`)

export const create = (data: PricingPlan) => api.post(PRICING_PLAN_URL, data)

export const update = (data: PricingPlan) =>
  api.put(`${PRICING_PLAN_URL}/${data.id}`, data)

export const remove = (id: number) => api.delete(`${PRICING_PLAN_URL}/${id}`)
