import React, { FC, useRef } from "react"
import useAdminTipList from "../hooks/useAdminTipList"
import useAdminTipDetail from "../hooks/useAdminTipDetail"
import AdminTipDetailModal from "../components/AdminTipDetailModal"
import { Col, Row, Table } from "reactstrap"
import { AdminTip } from "../../../types/AdminTip"
import { AiFillEdit } from "react-icons/ai"
import DeleteButtonIcon from "../../../components/Buttons/DeleteButtonIcon"
import SearchBoxContainer from "../../../components/Inputs/SearchBoxContainer"
import styles from "../styles/styles.module.scss";

const AdminTipList: FC = () => {
  const modal = useRef<any>()

  const {
    adminTipList,
    totalItems,
    filter,
    changeFilter,
    removeData,
    getData
  } = useAdminTipList()

  const {
    id,
    setId,
    confirmData,
    adminTipDetail,
    reset,
    listLanguage,
    activeLanguage,
    onChangeLanguage
  } = useAdminTipDetail()

  const openModal = (id?: number) => {
    !!id ? setId(id) : setId(0)
    modal && modal.current && modal.current.toggle()
  }

  const onConfirm = (values: any) => {
    confirmData(values, () => {
      modal && modal.current && modal.current.toggle()
      getData()
    })
  }

  return (
    <div className="animated fadeIn">
      <AdminTipDetailModal
          ref={modal}
          header={!!id ? "Edit" : "Create"}
          confirmText={!!id ? "Update" : "Create"}
          onConfirm={onConfirm}
          onCancel={reset}
          adminTipDetail={adminTipDetail}
          listLanguage={listLanguage}
          activeLanguage={activeLanguage}
          onChangeLanguage={onChangeLanguage}
        />
      <h4>Admin Tips List</h4>
      <Row className="mb-2">
        
        <Col md={8}>
            {totalItems <= 5 &&

              <SearchBoxContainer
                text="Add admin tip"
                onClick={() => openModal()}
                initValue={filter.search}
                onSearch={(string) => changeFilter({ search: string })}
              />
          }
        </Col>
        <Col md={4} className="d-flex justify-content-end align-items-center">
          <span className="font-weight-bold">
            Total admin tip: {totalItems}
          </span>
        </Col>
      </Row>
      <Table responsive bordered striped hover size="sm" className="mb-2">
        <thead>
          <tr>
            <th>Tip</th>
            <th className="text-center" style={{width: "100px"}}></th>
          </tr>
        </thead>
        <tbody>
          {adminTipList.length > 0 && adminTipList.map((adminTip: AdminTip) => (
            <tr key={adminTip.id}>
              <td><div className={`${styles["inner-html"]}`} dangerouslySetInnerHTML={{ __html: adminTip.text }}/></td>
              <td className="text-center">
                <AiFillEdit
                  className="mr-2 cursor-pointer hover-opacity"
                  onClick={() => openModal(adminTip.id)}
                />
                <DeleteButtonIcon
                  onClick={() => {
                    removeData(Number(adminTip.id))
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
export default AdminTipList