import { useCallback, useEffect, useState } from "react";
import { GuideType, IGuide } from "../constants/type";
import { useDispatch } from "react-redux";
import { setAlert, setLoading } from "../../../redux/commons/action";
import { customKeyUploadGuide, get, create } from "../../../services/guide";
import { deleteFile, getMedia } from "../../../services/fileServices";
import { initGuide } from "../constants/constant";

const useGuide = () => {
  const dispatch = useDispatch();
  const [guide, setGuide] = useState<IGuide[]>(initGuide);

  const handleChangeLink = (
    type: GuideType,
    value: string,
    isSubmit?: boolean
  ) => {
    const updatedGuide = guide.map((guideItem) =>
      guideItem.type === type ? { ...guideItem, source: value } : guideItem
    );

    setGuide(updatedGuide);

    if (isSubmit) {
      handleUpdateGuide(updatedGuide);
    }
  };

  const getSourceByType = (type: GuideType): string => {
    const guideItem = guide.find((item) => item.type === type);
    return guideItem ? guideItem.source : "";
  };

  const handelUpLoadFile = async (file: File, key: GuideType) => {
    dispatch(setLoading(true));

    try {
      const { name } = file;
      const customKey = `superAdmin-guide-${key}`;
      const formData = new FormData();

      formData.append("file", file, name);
      formData.append("customKey", customKey);

      const res = await getMedia({ prefix: `${customKey}/` });
      if (res?.data?.length > 0) {
        for (let index = 0; index < res.data.length; index++) {
          await deleteFile(res.data[index].key);
        }
      }

      await customKeyUploadGuide(formData);
      handleChangeLink(
        key,
        `${new URL(window.location.href).origin}/api/file/viewer?key=${customKey}/${name}`,
        true
      );
    } catch (err) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data?.title || err.message,
        })
      );
    }

    dispatch(setLoading(false));
  };

  const handleUpdateGuide = useCallback(async (model: IGuide[]) => {
    dispatch(setLoading(true));
    const res = await create(model);
    if (res.data) {
      dispatch(
        setAlert({
          type: "success",
          message: "Update success",
        })
      );
    }
    try {
    } catch (err) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data?.title || err.message,
        })
      );
    }

    dispatch(setLoading(false));
  }, []);

  const getCurrentGuide = useCallback(async () => {
    dispatch(setLoading(true));

    try {
      const res = await get();
      if (res.data?.length > 0) {
        setGuide(res.data);
      }
    } catch (err) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data?.title || err.message,
        })
      );
    }

    dispatch(setLoading(false));
  }, []);

  useEffect(() => {
    getCurrentGuide();
  }, []);

  return {
    guide,
    handleChangeLink,
    handelUpLoadFile,
    getSourceByType,
    handleUpdateGuide,
  };
};

export default useGuide;
