import { useState, useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { Filter } from "../../../types/Filter"
import { DEFAULT_FILTER } from "../../../utils/constants"
import { District } from "../../../types/District"
import { setLoading, setAlert, setModal } from "../../../redux/commons/action"
import { setDistrictList } from "../../../redux/districts/action"

import { get, remove } from "../../../services/districtService"

const DISTRICT_DETAIL_URL = "/districts"
const CREATE_DISTRICT_URL = "/create-district"
const useDistrictList = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [filter, setFilter] = useState<Filter>(DEFAULT_FILTER)
  const districtList: District[] = useSelector(
    (state: any) => state.districts.districtList
  )
  const totalItems: number = useSelector(
    (state: any) => state.districts.totalItems
  )

  const changeFilter = (valueObject: any) =>
    setFilter({ ...filter, ...valueObject })

  const getData = useCallback(async () => {
    dispatch(setLoading(true))
    try {
      const res = await get(filter)
      const { totalItems, items } = res.data
      dispatch(setDistrictList({ items, totalItems }))
    } catch (err) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data || err.message,
        })
      )
    }
    dispatch(setLoading(false))
  }, [dispatch, filter])

  const removeData = useCallback(
    async (id: number, name: string) => {
      dispatch(
        setModal({
          isOpen: true,
          type: "warning",
          message: `Do you want to delete this district ${name}?`,
          onConfirm: async () => {
            dispatch(setLoading(true))

            try {
              await remove(id)
              changeFilter({ page: 1 })
              dispatch(
                setAlert({
                  type: "success",
                  message: "Delete district successfully",
                })
              )
            } catch (err) {
              dispatch(
                setAlert({
                  type: "danger",
                  message: err.response?.data || err.message,
                })
              )
            }

            dispatch(setLoading(false))
          },
        })
      )
    },
    [dispatch, getData]
  )

  const goToDetail = useCallback((id?: number) => {
    const url = id ? `${DISTRICT_DETAIL_URL}/${id}` : CREATE_DISTRICT_URL
    history.push(url)
  }, [])

  useEffect(() => {
    getData()
  }, [filter])

  return {
    DISTRICT_DETAIL_URL,
    districtList,
    totalItems,
    filter,
    changeFilter,
    removeData,
    goToDetail,
  }
}

export default useDistrictList
