import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { setLoading, setAlert, setModal } from "../../../redux/commons/action"
import { PricingPlan } from "../../../types/PricingPlan"

import { getById, create, update } from "../../../services/pricingPlanService"

const initValue: PricingPlan = {
  name: "",
  numberOfAdmins: 0,
  numberOfAssistants: 0,
  numberOfStudents: 0,
  numberOfTeachers: 0,
}

const usePricingPlanDetail = () => {
  const dispatch = useDispatch()

  const [id, setId] = useState(0)
  const [pricingPlanDetail, setPricingPlanDetail] =
    useState<PricingPlan>(initValue)

  useEffect(() => {
    !!id ? getData() : setPricingPlanDetail(initValue)
  }, [id])

  const reset = () => {
    setPricingPlanDetail(initValue)
    setId(0)
  }

  const getData = useCallback(async () => {
    dispatch(setLoading(true))
    try {
      const res = await getById(id)
      setPricingPlanDetail(res.data)
    } catch (err) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data?.title || err.message,
        })
      )
    }
    dispatch(setLoading(false))
  }, [id])

  const confirmData = useCallback(
    async (values: any, cb: any) => {
      dispatch(setLoading(true))
      try {
        const next = !!id ? update : create

        await next(values)
        dispatch(
          setAlert({
            type: "success",
            message: `${!!id ? "Update" : "Create"} pricing plan successfully`,
          })
        )

        cb && cb()
        !!id && setId(0)
      } catch (err) {
        dispatch(
          setAlert({
            type: "danger",
            message: err.response?.data || err.message,
          })
        )
      }
      dispatch(setLoading(false))
    },
    [id]
  )

  return {
    id,
    setId,
    pricingPlanDetail,
    confirmData,
    initValue,
    reset,
  }
}
export default usePricingPlanDetail
