import { useCallback, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
import Pusher from "pusher-js/with-encryption"

import { setUnreadItems } from "../../../redux/notifications/action"

import { countUnreadNotifications } from "../../../services/notificationService"

import { PUSHER_CLUSTER, PUSHER_KEY } from "../../../utils/constants"

import { setAlert } from "../../../redux/commons/action"

const NOTIFICATION_CHANNEL = "NOTIFICATION_CHANNEL"
const NEW_NOTIFICATION = "NEW_NOTIFICATION"

const useUnReadNotification = () => {
  const dispatch = useDispatch()
  const unreadItems = useSelector(
    (state: any) => state.notifications.unreadItems
  )

  const getData = useCallback(async () => {
    try {
      const res = await countUnreadNotifications()
      dispatch(setUnreadItems(res.data))
    } catch (err) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data?.title || err.message,
        })
      )
    }
  }, [])

  useEffect(() => {
    getData()

    const pusher = new Pusher(PUSHER_KEY, { cluster: PUSHER_CLUSTER })
    const channel = pusher.subscribe(NOTIFICATION_CHANNEL)

    channel.bind(NEW_NOTIFICATION, getData)

    return () => pusher.unsubscribe(NOTIFICATION_CHANNEL)
  }, [])

  return { unreadItems }
}

export default useUnReadNotification
