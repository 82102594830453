import { createReducer } from "@reduxjs/toolkit"

import { setNotificationList, setUnreadItems } from "./action"

const initialState: any = {
  notificationList: [],
  totalItems: 0,
  unreadItems: 0,
}

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setNotificationList, (state, action) => {
      state.notificationList = action.payload.items
      state.totalItems = action.payload.totalItems
    })
    .addCase(setUnreadItems, (state, action) => {
      state.unreadItems = action.payload
    })
})

export default reducer
