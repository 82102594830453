import React from 'react';
import { CTabPane } from "@coreui/react"
import { Formik } from "formik"
import { FC } from "react"
import { Col, FormGroup, Input, Row } from "reactstrap"
import ErrorHandler from "../../../components/Alerts/ErrorHandler"
import RequiredLabel from "../../../components/Labels/RequiredLabel"
import { editorConfig, TINY_MCE_API_KEY } from "../constants/constant"
import schema from "../constants/legalPageShema"
import { LegalPage } from "../constants/type"
import { Editor } from "@tinymce/tinymce-react"
import DetailActionButtons from '../../../components/Buttons/DetailActionButtons';

interface Props{
    data: LegalPage
    index: number
    activeKey: number
    confirmData: Function
    setEditedTrue: Function
    onBack: Function
}

const TabPanel: FC<Props> = ({data, index, activeKey, confirmData, setEditedTrue, onBack}) => {
    return(
        <CTabPane
          role="tabpanel"
          active={activeKey === index}
        >
          {
            activeKey === index && data && (
            <Formik
                initialValues={data}
                enableReinitialize
                validationSchema={schema}
                onSubmit={values => {
                    confirmData(values)
                }}
            >
                {
                    formikProps => {
                        const {
                            values,
                            touched,
                            errors,
                            setFieldValue,
                            handleSubmit
                        } = formikProps

                        return (
                            <div className="animated fadeIn p-3">
                                <Row>
                                    <Col md={6} className="mb-2">
                                        <FormGroup>
                                            <RequiredLabel text="Title" />
                                            <Input
                                                type="text"
                                                value={values.title}
                                                onChange={(e: any) => {
                                                    setFieldValue(
                                                        "title",
                                                        e.target.value
                                                    )
                                                    setEditedTrue()
                                                }}
                                                placeholder="Title"
                                            />
                                            {
                                                touched.title && errors.title &&
                                                    (<ErrorHandler text={errors.title} />)
                                            }
                                        </FormGroup>
                                    </Col>
                                    <Col md={6} className="mb-2">
                                        <FormGroup>
                                            <RequiredLabel text="Url" />
                                            <Input
                                                type="text"
                                                value={values.url}
                                                onChange={(e: any) => {
                                                    setFieldValue(
                                                        "url",
                                                        e.target.value
                                                    )
                                                    setEditedTrue()
                                                }}
                                                placeholder="Url"
                                            />
                                            {
                                                touched.url && errors.url &&
                                                    (<ErrorHandler text={errors.url} />)
                                            }
                                        </FormGroup>
                                    </Col>
                                    <Col md={12} className="mb-2">
                                        <RequiredLabel text="Content body" />
                                        {(data.content || !data.id) && (
                                            <Editor
                                                apiKey={TINY_MCE_API_KEY}
                                                initialValue={
                                                    data.content
                                                }
                                                init={editorConfig}
                                                onEditorChange={(content: string) => {
                                                    setFieldValue(
                                                        "content",
                                                        content
                                                    )
                                                    if(content !== data.content)
                                                        setEditedTrue()
                                                }}
                                            />
                                        )}
                                        {touched.content && errors.content && (
                                            <ErrorHandler text={errors.content} />
                                        )}
                                    </Col>
                                    <Col md={12} className="mb-2 mt-3 pt-2 border-top">
                                        <DetailActionButtons
                                            confirmText="Save"
                                            onConfirm={handleSubmit}
                                            onCancel={() => {
                                                onBack()
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                }
            </Formik>
            )
          }
        </CTabPane>
    )
}

export default TabPanel