import React, { FC } from "react";
import { AiFillEdit } from "react-icons/ai";
import { Table, Col, Row } from "reactstrap";
import CustomPagination from "../../../components/Pagination/CustomPagination";
import DeleteButtonIcon from "../../../components/Buttons/DeleteButtonIcon";
import SearchBoxContainer from "../../../components/Inputs/SearchBoxContainer";
import { TINY_MCE_KEY } from "../constants/types";
import useTinyMCEKeyList from "../hooks/useTinyMCEKeyList";
import useTinyMCEKeyDetail from "../hooks/useTinyMCEKeyDetail";
import TinyMCEKeyDetailModal from "../components/TinyMCEKeyDetailModal";

const TinyMCEKeys: FC = () => {
  const {
    filter,
    data,
    handleChangeSearch,
    handleChangePage,
    handleChangeRowsPerPage,
    changeFilter,
    getData,
  } = useTinyMCEKeyList();

  const {
    data: dataDetail,
    modal,
    handleOpenModal,
    handleConfirm,
    reset,
    handleUpdate,
    handleDelete,
  } = useTinyMCEKeyDetail();
  const handleConfirmBox = (values: TINY_MCE_KEY) =>
    handleConfirm(values, () => {
      getData();
    });
  return (
    <div className="animated fadeIn">
      <TinyMCEKeyDetailModal
        ref={modal}
        header={
          !!dataDetail && !!dataDetail.id
            ? "Edit key"
            : "Create key"
        }
        confirmText={!!dataDetail && !!dataDetail.id ? "Update" : "Create"}
        onConfirm={handleConfirmBox}
        onCancel={reset}
        data={dataDetail}
      />
      <div className="mb-2 d-flex justify-content-between align-content-center">
        <h4>TinyMCE key</h4>
      </div>
      <Row className="mb-2">
        <Col md={8}>
          <SearchBoxContainer
            text="Add key"
            onClick={() => {
              handleOpenModal();
            }}
            initValue={filter.search}
            onSearch={handleChangeSearch}
          />
        </Col>
        <Col md={4} className="d-flex justify-content-end align-items-center">
          <span className="font-weight-bold">
            Total key: {data?.totalItems}
          </span>
        </Col>
      </Row>
      <Table responsive bordered striped hover size="sm" className="mb-2">
        <thead>
          <tr>
            <th>Key</th>
            <th className="text-nowrap">Number of school</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.items?.map((tinyMCEKey: TINY_MCE_KEY) => (
            <tr key={tinyMCEKey.id}>
              <td>{tinyMCEKey.key}</td>
              <td>{`${tinyMCEKey.schoolIds.length} ${
                tinyMCEKey.schoolIds.length > 1 ? "schools" : "school"
              }`}</td>
              <td className="text-center">
                <AiFillEdit
                  className="mr-2 cursor-pointer hover-opacity"
                  onClick={() => {
                    handleUpdate(tinyMCEKey.id);
                  }}
                />
                <DeleteButtonIcon
                  onClick={() => {
                    handleDelete(
                      tinyMCEKey.key,
                      tinyMCEKey.id,
                      changeFilter
                    );
                  }}
                />
              </td>
            </tr>
          ))}
          {(!data || !data.items.length) && (
            <tr>
              <td colSpan={12}>No data</td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className="text-right">
        <CustomPagination
          page={filter.page}
          limit={filter.limit}
          totalItems={data?.totalItems || 0}
          changePage={handleChangePage}
          changeLimit={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

export default TinyMCEKeys;
