import React, { FC } from "react";
import { AiFillEdit } from "react-icons/ai";
import { Table, Col, Row } from "reactstrap";
import CustomPagination from "../../../components/Pagination/CustomPagination";
import DeleteButtonIcon from "../../../components/Buttons/DeleteButtonIcon";
import SearchBoxContainer from "../../../components/Inputs/SearchBoxContainer";
import { NOTIFICATION_ALERT } from "../constants/types";
import useNotificationAlertList from "../hooks/useNotificationAlertList";
import useNotificationAlertDetail from "../hooks/useNotificationAlertDetail";
import NotificationAlertDetailModal from "../components/NotificationAlertDetailModal";

const NotificationAlertList: FC = () => {
  const {
    filter,
    data,
    handleChangeSearch,
    handleChangePage,
    handleChangeRowsPerPage,
    changeFilter,
    getData,
  } = useNotificationAlertList();

  const {
    data: dataDetail,
    modal,
    handleOpenModal,
    handleConfirm,
    reset,
    handleUpdate,
    handleDelete,
  } = useNotificationAlertDetail();
  const handleConfirmBox = (values: NOTIFICATION_ALERT) =>
    handleConfirm(values, () => {
      getData();
    });
  return (
    <div className="animated fadeIn">
      <NotificationAlertDetailModal
        ref={modal}
        header={
          !!dataDetail && !!dataDetail.id
            ? "Edit notification"
            : "Create notification"
        }
        confirmText={!!dataDetail && !!dataDetail.id ? "Update" : "Create"}
        onConfirm={handleConfirmBox}
        onCancel={reset}
        data={dataDetail}
      />
      <div className="mb-2 d-flex justify-content-between align-content-center">
        <h4>Notification</h4>
      </div>
      <Row className="mb-2">
        <Col md={8}>
          <SearchBoxContainer
            text="Add notification"
            onClick={() => {
              handleOpenModal();
            }}
            initValue={filter.search}
            onSearch={handleChangeSearch}
          />
        </Col>
        <Col md={4} className="d-flex justify-content-end align-items-center">
          <span className="font-weight-bold">
            Total notification: {data?.totalItems}
          </span>
        </Col>
      </Row>
      <Table responsive bordered striped hover size="sm" className="mb-2">
        <thead>
          <tr>
            <th>Notification</th>
            <th className="text-nowrap">Number of school</th>
            <th className="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.items?.map((notificationAlert: NOTIFICATION_ALERT) => (
            <tr key={notificationAlert.id}>
              <td>{notificationAlert.name}</td>
              <td>{`${notificationAlert.schoolIds.length} ${notificationAlert.schoolIds.length > 1 ? 'schools' : 'school'}`}</td>
              <td className="text-center">
                <AiFillEdit
                  className="mr-2 cursor-pointer hover-opacity"
                  onClick={() => {
                    handleUpdate(notificationAlert.id);
                  }}
                />
                <DeleteButtonIcon
                  onClick={() => {
                    handleDelete(
                      notificationAlert.name,
                      notificationAlert.id,
                      changeFilter
                    );
                  }}
                />
              </td>
            </tr>
          ))}
          {(!data || !data.items.length) && (
            <tr>
              <td colSpan={12}>No data</td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className="text-right">
        <CustomPagination
          page={filter.page}
          limit={filter.limit}
          totalItems={data?.totalItems || 0}
          changePage={handleChangePage}
          changeLimit={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

export default NotificationAlertList;
