import {useState, useEffect } from "react"
import { get } from "../../../services/schoolService"
import { SELECT_ALL_OPTION } from "../constants/constants"
import { SchoolOption } from "../constants/types"

const useSchoolSelector = () => {
    const [options, setOptions] = useState<SchoolOption[]>([])
    const [loading, setLoading] = useState<boolean>()

  const getData = async (textSearh?: string) => {
    setLoading(true)
    try {
      const res = await get({
        limit: 100,
        page: 1,
        search: textSearh || ""
      })
      const op = res.data.items.map((item: any) => ({
        value: item.id,
        label: item.name,
      }))
      setOptions(
        [
            SELECT_ALL_OPTION,
            ...op
        ]
      )
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }
  useEffect(() => {
    getData()
  }, [])
  return {
    options,
    getData,
    loading
  }
}

export default useSchoolSelector