import { useCallback, useEffect, useRef, useState } from "react";
import { Filter } from "../../../types/Filter";
import { useDispatch } from "react-redux";
import { DEFAULT_FILTER } from "../../../utils/constants";
import { setAlert, setLoading, setModal } from "../../../redux/commons/action";
import {
  create,
  get,
  getById,
  remove,
  update,
} from "../../../services/classLinkDomainService";
import { initClever } from "../constants/contsants";
import { ClassLinkDomain, DomainType } from "../../../types/ClassLinkDomain";

const useClever = () => {
  const dispatch = useDispatch();
  const modal = useRef<any>();
  const [filter, setFilter] = useState<Filter>(DEFAULT_FILTER);
  const [data, setData] = useState<ClassLinkDomain[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [id, setId] = useState(0);
  const [cleverDomainDetail, setCleverDomainDetail] =
    useState<ClassLinkDomain>(initClever);

  const changeFilter = (valueObject: any) =>
    setFilter({
      ...filter,
      ...valueObject,
    });

  const reset = () => {
    setCleverDomainDetail(initClever);
    setId(0);
  };

  const openModal = (id?: number) => {
    !!id ? setId(id) : setId(0);
    modal && modal.current && modal.current.toggle();
  };

  const onConfirm = (values: any) => {
    confirmData(values, () => {
      modal && modal.current && modal.current.toggle();
      getData();
    });
  };

  const getData = useCallback(async () => {
    dispatch(setLoading(true));

    try {
      const res = await get(filter, DomainType.Clever);
      const { totalItems, items } = res.data;
      setData(items);
      setTotalItems(totalItems);
    } catch (err: any) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data || err.message,
        })
      );
    }
    dispatch(setLoading(false));
  }, [dispatch, filter]);

  const getDataById = useCallback(async () => {
    dispatch(setLoading(true));
    try {
      const res = await getById(id);
      setCleverDomainDetail(res.data);
    } catch (err: any) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data?.title || err.message,
        })
      );
    }
    dispatch(setLoading(false));
  }, [id]);

  const confirmData = useCallback(
    async (values: any, cb: any) => {
      dispatch(setLoading(true));
      try {
        const next = !!id ? await update : await create;
        values.tenantId = values.tenantId.trim();
        values.name = values.name.trim();
        values.orgId = "";
        values.email = values.email.trim();
        values.type = DomainType.Clever

        await next(values);
        dispatch(
          setAlert({
            type: "success",
            message: `${!!id ? "Update" : "Create"} clever domain successfully`,
          })
        );

        cb && cb();
        !!id && setId(0);
      } catch (err: any) {
        dispatch(
          setAlert({
            type: "danger",
            message: err.response?.data || err.message,
          })
        );
      }
      dispatch(setLoading(false));
    },
    [id]
  );

  const removeData = useCallback(
    async (id: number) => {
      dispatch(
        setModal({
          isOpen: true,
          type: "warning",
          message: `Do you want to delete this clever?`,
          onConfirm: async () => {
            dispatch(setLoading(true));

            try {
              await remove(id);
              changeFilter({ page: 1 });
              dispatch(
                setAlert({
                  type: "success",
                  message: "Delete clever successfully",
                })
              );
            } catch (err: any) {
              dispatch(
                setAlert({
                  type: "danger",
                  message: err.response?.data || err.message,
                })
              );
            }

            dispatch(setLoading(false));
          },
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    getData();
  }, [filter]);

  useEffect(() => {
    !!id ? getDataById() : setCleverDomainDetail(initClever);
  }, [id]);

  return {
    data,
    totalItems,
    filter,
    changeFilter,
    removeData,
    getData,
    id,
    setId,
    cleverDomainDetail,
    confirmData,
    reset,
    openModal,
    onConfirm,
    modal,
  };
};

export default useClever;
