import { createAction } from "@reduxjs/toolkit"

import { School } from "../../types/School"

interface SchoolList {
  items: School[]
  totalItems: number
}

export const setSchoolList = createAction<SchoolList>("schools/setSchoolList")
