import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { setAlert, setLoading, setModal } from "../../../redux/commons/action";
import {
  create,
  getById,
  remove,
  update,
} from "../../../services/notificationAlertService";
import { NOTIFICATION_ALERT } from "../constants/types";
const useNotificationAlertDetail = () => {
  const defaultValue = {
    name: "",
    schoolIds: [],
  };
  const [data, setData] = useState<NOTIFICATION_ALERT>(defaultValue);
  const modal = useRef<any>();

  const dispatch = useDispatch();
  const handleOpenModal = (id?: number) => {
    !!modal && !!modal.current && modal.current.toggle();
    !!id && getData(id);
  };
  const handleCloseModal = () => {
    !!modal && !!modal.current && modal.current.toggle();
  };

  const reset = () => {
    setData(defaultValue);
  };

  const getData = async (id: number) => {
    dispatch(setLoading(true));
    try {
      const res = await getById(id);
      !!res.data && setData(res.data);
    } catch (err) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data || err.message,
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  const createData = async (values: NOTIFICATION_ALERT, cb?: any) => {
    dispatch(setLoading(true));
    try {
      await create(values);
      !!cb && cb();
    } catch (err) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data || err.message,
        })
      );
    }
    dispatch(setLoading(false));
  };
  const updateData = async (values: NOTIFICATION_ALERT, cb?: () => void) => {
    dispatch(setLoading(true));
    try {
      await update(values);
      !!cb && cb();
    } catch (err) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data || err.message,
        })
      );
    }
    dispatch(setLoading(false));
  };

  const handleConfirm = async (values: NOTIFICATION_ALERT, cb?: () => void) => {
    if (!!data.id) updateData(values, cb);
    else createData(values, cb);
    handleCloseModal();
  };

  const handleUpdate = (id?: number) => {
    handleOpenModal(id);
  };

  const handleDelete = async (
    name: string,
    id?: number,
    changeFilter?: any
  ) => {
    if (!id) return;
    dispatch(
      setModal({
        isOpen: true,
        type: "warning",
        message: `Do you want to delete this notification ${name}?`,
        onConfirm: async () => {
          dispatch(setLoading(true));

          try {
            await remove(id);
            !!changeFilter && changeFilter({ page: 1 });
            dispatch(
              setAlert({
                type: "success",
                message: "Delete notification successfully",
              })
            );
          } catch (err) {
            dispatch(
              setAlert({
                type: "danger",
                message: err.response?.data || err.message,
              })
            );
          }

          dispatch(setLoading(false));
        },
      })
    );
  };

  return {
    data,
    modal,
    handleOpenModal,
    handleCloseModal,
    handleConfirm,
    reset,
    handleUpdate,
    handleDelete,
  };
};

export default useNotificationAlertDetail;
