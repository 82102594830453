import { api } from "./api"
import { Filter } from "../types/Filter"
import { District } from "../types/District"

const DISTRICT_URL = "/api/District"

export const get = (filter?: Filter) =>
  api.get(DISTRICT_URL, { params: filter })

export const getById = (id: number) => api.get(`${DISTRICT_URL}/${id}`)

export const create = (data: District) => api.post(DISTRICT_URL, data)

export const update = (data: District) =>
  api.put(`${DISTRICT_URL}/${data.id}`, data)

export const remove = (id: number) => api.delete(`${DISTRICT_URL}/${id}`)
