import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { setAlert, setLoading } from "../../../redux/commons/action"
import { getById, update, create, remove } from "../../../services/legalPageService"
import { DEFAULT_LEGAL_PAGE } from "../constants/constant"
import { LegalPage } from "../constants/type"

interface Props{
    defaulValue: LegalPage
    getListData: Function
    activeKey: number
}

const useLegalPageDetail = (props: Props) => {
    const { defaulValue, getListData, activeKey } = props
    const [legalPage, setLegalPage] = useState<LegalPage>(DEFAULT_LEGAL_PAGE)
    const [edited, setEdited] = useState<boolean>(false)
    const history = useHistory()

    const dispatch = useDispatch()

    const setEditedTrue = async () => {
        localStorage.setItem(`${window.location.href}`, "true")
        setEdited(true)
    }
    const setEditedFalse = async () => {
        !!localStorage.getItem(`${window.location.href}`) &&
            localStorage.removeItem(`${window.location.href}`)
        setEdited(false)
    }

    const getData = useCallback(async () => {
        if(!defaulValue?.id){
            setLegalPage({...DEFAULT_LEGAL_PAGE, ...defaulValue})
            return;
        }
        dispatch(setLoading(true))
        try {
            const res = await getById(defaulValue?.id)
            setLegalPage(res.data)
        } catch (err) {
            dispatch(
                setAlert({
                  type: "danger",
                  message: err.response?.data?.title || err.message,
                })
              );
        }
        dispatch(setLoading(false))
    }, [defaulValue])

    const confirm = useCallback(
      async (data: LegalPage) => {
        const next = !!defaulValue?.id ? update : create
        dispatch(setLoading(true))
        try {
          await next({...data, language: legalPage.language})
          dispatch(
            setAlert({
              type: "success",
              message: `${!!defaulValue?.id ? "Update" : "Create"} legal page successfully`,
            })
          )
        } catch (err) {
          dispatch(
            setAlert({
              type: "danger",
              message: err.response?.data?.title || err.message,
            })
          )
        }
        dispatch(setLoading(false))
        setEditedFalse()
        getListData()
        history.push("/legal-pages")
      },
      [defaulValue, legalPage]
    )

    const backToFirst = () => {
      if(activeKey === 0)
      {
        setLegalPage({...DEFAULT_LEGAL_PAGE, id: legalPage.id})
        getData()
        setEditedFalse()
      }
      history.push("/legal-pages")
    }
    
    useEffect(() => {
        getData()
    }, [defaulValue])

    useEffect(() => {
        setEditedFalse()
    }, [window.location.href])
    return{
        legalPage,
        edited,
        confirm,
        setEditedTrue,
        setEdited,
        backToFirst
    }
}

export default useLegalPageDetail