import { combineReducers } from "redux"

import commons from "./redux/commons/reducer"
import schools from "./redux/schools/reducer"
import pricingPlans from "./redux/pricingPlans/reducer"
import districts from "./redux/districts/reducer"
import feedbacks from "./redux/feedbacks/reducer"
import notifications from "./redux/notifications/reducer"
import gallery from "./redux/gallery/reducer"

const rootReducer = combineReducers({
  commons,
  schools,
  pricingPlans,
  districts,
  feedbacks,
  notifications,
  gallery
})

export default rootReducer
