import { uploadFile } from "../../../services/fileServices"
import getFileUrl from "../../../utils/getFileUrl"
import { LegalPage } from "./type"

export const DEFAULT_LEGAL_PAGES: LegalPage[] = [
    {
        language: "us",
        title: "",
        url: "",
        content: ""
    },
    {
        language: "uk",
        title: "",
        url: "",
        content: ""
    }
]

export const DEFAULT_LEGAL_PAGE: LegalPage = {
    language: "",
    title: "",
    url: "",
    content: ""
}

export const TINY_MCE_API_KEY =
    "adpshj9swgsscf83gw4gs2f74nx0vicpd5ydka20iqjd7l8r"

const config: any = {
    height: 500,
    menubar: false,
    force_br_newlines: true,
    force_p_newlines: false,
    branding: false,
    convert_urls: false,
    plugins: `advlist autolink lists link image charmap print preview anchor
        searchreplace visualblocks code fullscreen
        insertdatetime media table paste code help wordcount media`,
    toolbar: `undo redo | formatselect | bold italic backcolor | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | removeformat | image`,
    paste_data_images: true
}

export const editorConfig = {
    ...config,
    images_upload_handler: async function (blobInfo: any, success: Function) {
        const imageFile = new FormData()
        imageFile.append("file", blobInfo.blob(), blobInfo.filename())
        try {
            const { data } = await uploadFile(imageFile)
            success(getFileUrl(data.key))
        } catch (err) {
            return
        }
    }
}