import { createReducer } from "@reduxjs/toolkit"

import { setSchoolList } from "./action"

const initialState: any = {
  schoolList: [],
  totalItems: 0,
}

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(setSchoolList, (state, action) => {
    state.schoolList = action.payload.items
    state.totalItems = action.payload.totalItems
  })
})

export default reducer
