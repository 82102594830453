import React, { forwardRef } from "react";
import CommonModal from "../../../components/Modals/CommonModal";
import RequiredLabel from "../../../components/Labels/RequiredLabel";
import ErrorHandler from "../../../components/Alerts/ErrorHandler";

import * as yup from "yup";
import { Formik } from "formik";
import { Col, Input, Label, Row } from "reactstrap";
import { NOTIFICATION_ALERT, SchoolOption } from "../constants/types";
import SchoolSelector from "./SchoolSelector";

const schema = yup.object({
  name: yup.string().trim().required("Notification is required"),
  schoolIds: yup.array().of(yup.number())
});

interface Props {
  header: string;
  confirmText: string;
  onConfirm: (values: NOTIFICATION_ALERT) => void;
  data: NOTIFICATION_ALERT;
  onCancel: () => void;
}

const NotificationAlertDetailModal = (
  { header, confirmText, onConfirm, data, onCancel } : Props,
  ref: any
) => {
  return (
    <Formik
      initialValues={data}
      enableReinitialize
      validationSchema={schema}
      onSubmit={(values: NOTIFICATION_ALERT, actions: any) => {
        onConfirm(values);
        actions.resetForm();
      }}
    >
      {(formikProps: any) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          setFieldValue
        } = formikProps;
        return (
          <CommonModal
            header={header}
            confirmText={confirmText}
            onConfirm={handleSubmit}
            onCancel={() => {
              onCancel();
              resetForm();
            }}
            ref={ref}
          >
            <Row>
              <Col md={12} className="mb-2">
                <Label>Select School</Label>
                    <SchoolSelector
                      schoolIds={values.schoolIds}
                      onChange={(e: SchoolOption | Array<SchoolOption>) => {
                        let value;
                        if(Array.isArray(e))
                        {
                          value = e.map((i: any) => i.value)
                        }
                        else{
                          value = e ? [e.value] : []
                        }
                        if(value.includes(-1))
                        {
                          setFieldValue("schoolIds", [-1])
                          setFieldValue("isSingle", true)
                        }else{
                          setFieldValue("schoolIds", value)
                          setFieldValue("isSingle", false)
                        }
                      }}
                      isSingle={values.isSingle}
                    />
              </Col>
              <Col md={12} className="mb-2">
                <RequiredLabel text="Notification" />
                <Input
                  name="name"
                  placeholder="Enter notification..."
                  type="textarea"
                  rows="5"
                  value={values.name}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur("name")}
                />
                {touched.name && errors.name && (
                  <ErrorHandler text={errors.name as string} />
                )}
              </Col>
            </Row>
          </CommonModal>
        );
      }}
    </Formik>
  );
};

export default forwardRef(NotificationAlertDetailModal);
