import { LegalPage } from "./type";

export const getUrlRidrect = (data: LegalPage, index: number) => {
    const URL = '/legal-pages'
    if(!!data.id)
    {
        if(index === 0) return URL
        return `${URL}?id=${data.id}`
    }

    if(index > 0) return `${URL}?default=${index}`
    return URL
}