import React, { forwardRef } from "react"
import CommonModal from "../../../components/Modals/CommonModal"
import RequiredLabel from "../../../components/Labels/RequiredLabel"
import ErrorHandler from "../../../components/Alerts/ErrorHandler"

import * as yup from "yup"
import { Formik } from "formik"
import { Col, Input, Row } from "reactstrap"

const schema = yup.object({
  name: yup.string().required("Name is required"),
  numberOfAdmins: yup
    .number()
    .test(
      "numberOfAdmins",
      "Number of Admins is greater than 0",
      (number) => !!number && number >= 1
    ),
  numberOfAssistants: yup
    .number()
    .test(
      "numberOfAssistants",
      "Number of Assistants is greater than 0",
      (number) => !!number && number >= 1
    ),
  numberOfStudents: yup
    .number()
    .test(
      "numberOfStudents",
      "Number of Students is greater than 0",
      (number) => !!number && number >= 1
    ),
  numberOfTeachers: yup
    .number()
    .test(
      "numberOfTeachers",
      "Number of Teachers is greater than 0",
      (number) => !!number && number >= 1
    ),
})

const PricingPlanDetailModal = (props: any, ref: any) => {
  const { header, confirmText, onConfirm, pricingPlanDetail, onCancel } = props
  return (
    <Formik
      initialValues={pricingPlanDetail}
      enableReinitialize
      validationSchema={schema}
      onSubmit={(values: any, actions: any) => {
        onConfirm(values)
        actions.resetForm()
      }}
    >
      {(formikProps: any) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
        } = formikProps

        return (
          <CommonModal
            header={header}
            confirmText={confirmText}
            onConfirm={handleSubmit}
            onCancel={() => {
              onCancel()
              resetForm()
            }}
            ref={ref}
          >
            <Row>
              <Col md={12} className="mb-2">
                <RequiredLabel text="Name" />
                <Input
                  name="name"
                  placeholder="Name"
                  value={values.name}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  onBlur={handleBlur("name")}
                />
                {touched.name && errors.name && (
                  <ErrorHandler text={errors.name as string} />
                )}
              </Col>
              <Col md={6}>
                <RequiredLabel text="Number Of Admins" />
                <Input
                  name="numberOfAdmins"
                  value={!!values.numberOfAdmins && values.numberOfAdmins}
                  type="number"
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  onBlur={handleBlur("numberOfAdmins")}
                />
                {touched.numberOfAdmins && errors.numberOfAdmins && (
                  <ErrorHandler text={errors.numberOfAdmins as string} />
                )}
              </Col>
              <Col md={6}>
                <RequiredLabel text="Number Of Assistants" />
                <Input
                  name="numberOfAssistants"
                  value={
                    !!values.numberOfAssistants && values.numberOfAssistants
                  }
                  type="number"
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  onBlur={handleBlur("numberOfAssistants")}
                />
                {touched.numberOfAssistants && errors.numberOfAssistants && (
                  <ErrorHandler text={errors.numberOfAssistants as string} />
                )}
              </Col>
              <Col md={6}>
                <RequiredLabel text="Number Of Students" />
                <Input
                  name="numberOfStudents"
                  value={!!values.numberOfStudents && values.numberOfStudents}
                  type="number"
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  onBlur={handleBlur("numberOfStudents")}
                />
                {touched.numberOfStudents && errors.numberOfStudents && (
                  <ErrorHandler text={errors.numberOfStudents as string} />
                )}
              </Col>
              <Col md={6}>
                <RequiredLabel text="Number Of Teachers " />
                <Input
                  name="numberOfTeachers"
                  value={!!values.numberOfTeachers && values.numberOfTeachers}
                  type="number"
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  onBlur={handleBlur("numberOfTeachers")}
                />
                {touched.numberOfTeachers && errors.numberOfTeachers && (
                  <ErrorHandler text={errors.numberOfTeachers as string} />
                )}
              </Col>
            </Row>
          </CommonModal>
        )
      }}
    </Formik>
  )
}

export default forwardRef(PricingPlanDetailModal)
