import React, { FC } from "react"
import { Card, CardHeader, CardBody } from "reactstrap"

import { PricingPlan } from "../../../types/PricingPlan"

interface Props {
  pricingPlan?: PricingPlan | null
  pricingPlanIsOver: boolean
}

const PricingPlanInformation: FC<Props> = ({
  pricingPlan,
  pricingPlanIsOver,
}: Props) => {
  if (!pricingPlan) return null
  return (
    <Card>
      <CardHeader>
        Pricing plan: {pricingPlan.name}
        {pricingPlanIsOver && <span className="text-danger">*</span>}
      </CardHeader>
      <CardBody>
        <p className="font-weight-bold">
          Maximum admins allow: {pricingPlan.numberOfAdmins}
        </p>
        <p className="font-weight-bold">
          Maximum teachers allow: {pricingPlan.numberOfTeachers}
        </p>
        <p className="font-weight-bold">
          Maximum assistants allow: {pricingPlan.numberOfAssistants}
        </p>
        <p className="font-weight-bold">
          Maximum students allow: {pricingPlan.numberOfStudents}
        </p>
      </CardBody>
    </Card>
  )
}

export default PricingPlanInformation
