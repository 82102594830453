import * as yup from "yup"

const schema = yup.object({
  name: yup.string().required("School name is required"),
  address: yup.string().nullable(),
  contactPersonEmail: yup.string().required("Contact person email"),
  contactPersonName: yup.string().nullable(),
  subDomain: yup.string().required("Sub domain is required"),
  connectionString: yup.string().required("Connection string is required"),
  numberOfAdmins: yup.number(),
  numberOfAssistants: yup.number(),
  numberOfStudents: yup.number(),
  numberOfTeachers: yup.number(),
  pricingPlanId: yup.number().nullable(),
})

export default schema
