import { useState, useEffect } from "react";
import { DATA_RESPONSE, FILTER, TINY_MCE_KEY } from "../constants/types";
import { useDispatch } from "react-redux";
import { setAlert, setLoading } from "../../../redux/commons/action";
import { get } from "../../../services/tinyMCEKeyService";

const useTinyMCEKeyList = () => {
  const defaultFilter: FILTER = {
    search: "",
    page: 1,
    limit: 30,
  };
  const TINY_MCE_KEY_DETAIL_URL = "";
  const [data, setData] = useState<DATA_RESPONSE<TINY_MCE_KEY>>();
  const [filter, setFilter] = useState<FILTER>({ ...defaultFilter });

  const dispatch = useDispatch();

  const changeFilter: (newFilter: FILTER) => void = (newFilter: FILTER) => {
    setFilter({ ...filter, ...newFilter });
  };

  const getData = async () => {
    dispatch(setLoading(true));
    try {
      const res = await get(filter);
      !!res.data && setData(res.data);
    } catch (err) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data || err.message,
        })
      );
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleChangeSearch = (searchString: string | undefined) => {

      changeFilter({
        ...filter,
        search: searchString || "",
        page: 1,
      })
  };
  // end handle filter

  // handle table action
  const handleChangePage = (newPage: number) => {
    changeFilter({ ...filter, page: newPage });
  };
  const handleChangeRowsPerPage = (pageSize: number) => {
    if (!Number.isNaN(pageSize))
      changeFilter({ ...filter, limit: pageSize, page: 1 });
  };

  useEffect(() => {
    const controller = new AbortController();
    getData();
    return controller.abort();
  }, [filter]);

  return {
    TINY_MCE_KEY_DETAIL_URL,
    data,
    filter,
    handleChangeSearch,
    handleChangePage,
    handleChangeRowsPerPage,
    changeFilter,
    getData
  };
};

export default useTinyMCEKeyList;
