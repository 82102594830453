import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { CHeader, CToggler, CHeaderBrand, CHeaderNav } from "@coreui/react"

import { setSidebar } from "../redux/commons/action"

import TheHeaderDropdown from "./TheHeaderDropdown"
import NotificationButton from "../containers/Notifcations/components/NotificationButton"

import Images from "../assets/constants/Images"

const TheHeader = () => {
  const dispatch = useDispatch()
  const sidebarShow = useSelector((state: any) => state.commons.sidebarShow)

  const toggleSidebar = () => {
    const val = [true, "responsive"].includes(sidebarShow)
      ? false
      : "responsive"
    dispatch(setSidebar(val))
  }

  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(sidebarShow)
      ? true
      : "responsive"
    dispatch(setSidebar(val))
  }

  return (
    <CHeader
      withSubheader
      className="d-flex align-items-center justify-content-between"
    >
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebar}
      />
      <CHeaderBrand className="mx-auto flex-grow-1 d-lg-none" to="/">
        <img src={Images.LOGO_HEADER} className="header-logo" />
      </CHeaderBrand>

      <CHeaderNav className="pr-3">
        <NotificationButton />
        <TheHeaderDropdown />
      </CHeaderNav>
    </CHeader>
  )
}

export default TheHeader
