import SchoolList from "../containers/Schools/views/SchoolList"
import SchoolDetail from "../containers/Schools/views/SchoolDetail"
import PricingPlanList from "../containers/PricingPlans/views/PricingPlanList"
import FeedbackList from "../containers/Feedbacks/views/FeedbackList"
import DistrictList from "../containers/Districts/views/DistrictList"
import NotificationList from "../containers/Notifcations/views/NoticationList"
import DistrictDetail from "../containers/Districts/views/DistrictDetail"
import GalleryList from "../containers/Gallery/views/GalleryList"
import NotificationAlertList from "../containers/NotificationAlerts/views/NotificationAlerts"
import LegalPageList from "../containers/LegalPages/views/LegalPageList"
import Guide from "../containers/Guide/views/Guide"
import AdminTipList from "../containers/AdminTips/views/AdminTipList"
import ClassLinkDomainList from "../containers/ClassLinkDomains/views/ClassLinkDomainList"
import CleverDomainList from "../containers/CleverDomains/views/CleverDomainList"
import TinyMCEKeys from "../containers/TinyMCEKey/views/TinyMCEKeys"
import LanguageList from "../containers/Languages/views/LanguageList"

const routes = [
  {
    path: "/schools",
    component: SchoolList,
    exact: true,
  },
  {
    path: "/schools/:id",
    component: SchoolDetail,
    exact: true,
  },
  {
    path: "/create-school",
    component: SchoolDetail,
    exact: true,
  },
  {
    path: "/pricing-plans",
    component: PricingPlanList,
    exact: true,
  },
  {
    path: "/districts",
    component: DistrictList,
    exact: true,
  },
  {
    path: "/districts/:id",
    component: DistrictDetail,
    exact: true,
  },
  {
    path: "/create-district",
    component: DistrictDetail,
    exact: true,
  },
  {
    path: "/feedbacks",
    component: FeedbackList,
    exact: true,
  },
  {
    path: "/notifications",
    component: NotificationList,
    exact: true,
  },
  {
    path: "/notification-alerts",
    component: NotificationAlertList,
    exact: true,
  },
  {
    path: "/tiny-mce",
    component: TinyMCEKeys,
    exact: true,
  },
  {
    path: "/library",
    component: GalleryList,
    exact: true
  },
  {
    path: "/legal-pages",
    component: LegalPageList,
  },
  {
    path: "/guide",
    component: Guide,
  },
  {
    path: "/admin-tips",
    component: AdminTipList,
    exact: true
  },
  {
    path: "/classlink",
    component: ClassLinkDomainList
  },
  {
    path: "/clever",
    component: CleverDomainList
  },
  {
    path: "/languages",
    component: LanguageList
  }
]

export default routes
