import { api, apiUpload } from "./api"
import { Filter } from "../types/Filter"
import { School } from "../types/School"

const SCHOOL_URL = "/api/school"
const UPLOAD_FILE_URL = `/api/file`

export const get = (filter: Filter) => api.get(SCHOOL_URL, { params: filter })

export const getById = (id: number) => api.get(`${SCHOOL_URL}/${id}`)

export const create = (data: School) => api.post(SCHOOL_URL, data)

export const update = (data: School) =>
  api.put(`${SCHOOL_URL}/${data.id}`, data)

export const remove = (id: number) => api.delete(`${SCHOOL_URL}/${id}`)

export const systemUpload = (formData: FormData) =>
  apiUpload.post(UPLOAD_FILE_URL, formData)
