import { useDispatch } from "react-redux"
import { ClassLinkDomain, DomainType } from "../../../types/ClassLinkDomain"
import { useCallback, useEffect, useState } from "react"
import { setAlert, setLoading } from "../../../redux/commons/action"
import { create, getById, update } from "../../../services/classLinkDomainService"

const initValue: ClassLinkDomain = {
  tenantId: "",
  orgId: "",
  name: "",
  email: "",
  schoolId: 0
}

const useClassLinkDomainDetail = () => {
  const dispatch = useDispatch()

  const [id, setId] = useState(0)
  const [classLinkDomainDetail, setClassLinkDomainDetail] = useState<ClassLinkDomain>(initValue)

  useEffect(() => {
    !!id ? getData() : setClassLinkDomainDetail(initValue)
  }, [id])

  const reset = () => {
    setClassLinkDomainDetail(initValue)
    setId(0)
  }

  const getData = useCallback(async () => {
    dispatch(setLoading(true))
    try {
      const res = await getById(id)
      setClassLinkDomainDetail(res.data)
    } catch (err: any) {
      dispatch(
        setAlert({
          type: "danger",
          message: err.response?.data?.title || err.message,
        })
      )
    }
    dispatch(setLoading(false))
  }, [id])

  const confirmData = useCallback(
    async (values: any, cb: any) => {
      dispatch(setLoading(true))
      try {
        const next = !!id ? await update : await create
        values.tenantId = values.tenantId.trim()
        values.name = values.name.trim()
        values.orgId = values.orgId.trim()
        values.type = DomainType.ClassLink
        values.email = values.email.trim()
        
        await next(values)
        dispatch(
          setAlert({
            type: "success",
            message: `${!!id ? "Update" : "Create"} classlink domain successfully`,
          })
        )

        cb && cb()
        !!id && setId(0)
      } catch (err: any) {
        dispatch(
          setAlert({
            type: "danger",
            message: err.response?.data || err.message,
          })
        )
      }
      dispatch(setLoading(false))
    },
    [id]
  )

  return {
    id, 
    setId,
    classLinkDomainDetail,
    confirmData,
    initValue,
    reset
  }
}

export default useClassLinkDomainDetail