import { createAction } from "@reduxjs/toolkit"

import { District } from "../../types/District"

interface DistrictList {
  items: District[]
  totalItems: number
}

export const setDistrictList = createAction<DistrictList>(
  "districts/setDistrictList"
)
