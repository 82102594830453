import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
import { setAlert, setLoading } from "../../../redux/commons/action"
import { get } from "../../../services/legalPageService"
import { DEFAULT_LEGAL_PAGE, DEFAULT_LEGAL_PAGES } from "../constants/constant"
import { LegalPage } from "../constants/type"

const useLegalPageList = () => {
    const [legalPages, setLegalPages] = useState<LegalPage[]>([])
    const [activeKey, setActiveKey] = useState<number>(0);
    const [ seclectedData, setSelectedData ] = useState<LegalPage>(DEFAULT_LEGAL_PAGE)
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const id = query.get("id") || null;
    const defaultId = query.get("default") || null;

    const dispatch = useDispatch()

    const getActiveValueFromId = () => {
        if(!id && !defaultId){
            setActiveKey(0)
            setSelectedData(legalPages?.[0] || DEFAULT_LEGAL_PAGE)
        }
        if(id){
            const parsedId = parseInt(id)
            const index = legalPages.findIndex((item: LegalPage) => item.id == parsedId)
            if(index >= 0){
                setActiveKey(index)
                setSelectedData(legalPages[index])
            }
            else{
                setSelectedData(DEFAULT_LEGAL_PAGE)
            }
            return;
        }
        if(defaultId){
            const parsedId = parseInt(defaultId)
            setActiveKey(parsedId)
            setSelectedData(legalPages?.[parsedId] || DEFAULT_LEGAL_PAGE)
        }
    }

    const getData = useCallback(async () => {
        dispatch(setLoading(true))
        try{
            const res = await get()
            if(res.data?.length > 0){
                const data = res.data.filter((item: LegalPage) => DEFAULT_LEGAL_PAGES.some((l: LegalPage) => item.language === l.language))
                const remain = DEFAULT_LEGAL_PAGES.filter((item: LegalPage) => !res.data.some((l: LegalPage) => item.language === l.language))
                setLegalPages([...data, ...remain])
            }
            else
                setLegalPages([...DEFAULT_LEGAL_PAGES])
        }catch(err){
            dispatch(
                setAlert({
                  type: "danger",
                  message: err.response?.data?.title || err.message,
                })
              );
        }
        dispatch(setLoading(false))
    }, [legalPages])

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        getActiveValueFromId()
    }, [id, defaultId, legalPages])

    return {
        seclectedData,
        legalPages,
        activeKey,
        setActiveKey,
        getData
    }
}

export default useLegalPageList