import { apiUpload, api } from "./api"
import { FilterType } from "./../containers/Gallery/constants/gallery.types"

const FILE_URL = `/api/file`
const DELETE_FILE = `${FILE_URL}/delete-file`
const DELETE_FOLDER = `${FILE_URL}/delete-folder`
const CREATE_FOLDER = `${FILE_URL}/create-folder`
const RENAME_FILE = `${FILE_URL}/rename-file`
const CUSTOM_KEY = `${FILE_URL}/custom-key`

export const getMedia = (filter: FilterType) =>
    api.get(FILE_URL, { params: filter })

export const uploadFile = (formData: FormData) =>
    apiUpload.post(FILE_URL, formData)

export const deleteFile = (key: string) =>
    api.delete(`${DELETE_FILE}?key=${key}`)

export const deleteFolder = (key: string) =>
    api.delete(`${DELETE_FOLDER}?key=${key}`)

export const createFolder = (prefix: string, folderName: string) =>
    api.post(CREATE_FOLDER, {
        prefix: prefix || "",
        folderName: folderName
    })

export const customKeyUpload = (formData: FormData) =>
    apiUpload.post(CUSTOM_KEY, formData)

export const renameFile = (key: string, name: string) =>
    api.post(RENAME_FILE, { key: key, name: name })
