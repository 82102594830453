import { api } from "./api"
import { Filter } from "../types/Filter"
import { Language } from "../types/Language"

const LANGUAGE_URL = "/api/Language"

export const get = (filter?: Filter) =>
  api.get(LANGUAGE_URL, { params: filter })

export const getById = (id: number) => api.get(`${LANGUAGE_URL}/${id}`)

export const create = (data: Language) => api.post(LANGUAGE_URL, data)

export const update = (data: Language) =>
  api.put(`${LANGUAGE_URL}/${data.id}`, data)

export const remove = (id: number) => api.delete(`${LANGUAGE_URL}/${id}`)