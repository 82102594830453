import React, { FC } from "react"
import { Link } from "react-router-dom"
import { Table, Button, Input, Row, Col } from "reactstrap"

import CustomPagination from "../../../components/Pagination/CustomPagination"
import DeleteButtonIcon from "../../../components/Buttons/DeleteButtonIcon"
import SearchBoxContainer from "../../../components/Inputs/SearchBoxContainer"

import useSchoolList from "../hooks/useSchoolList"

const SchoolList: FC = () => {
  const {
    SCHOOL_DETAIL_URL,
    schoolList,
    totalItems,
    filter,
    removeData,
    changeFilter,
    goToDetail,
  } = useSchoolList()

  return (
    <div className="animated fadeIn">
      <h4>School list</h4>
      <Row className="mb-2">
        <Col md={8}>
          <SearchBoxContainer
            text="Add school"
            onClick={() => goToDetail()}
            initValue={filter.search}
            onSearch={(string) => changeFilter({ search: string })}
          />
        </Col>
        <Col md={4} className="d-flex justify-content-end align-items-center">
          <span className="font-weight-bold">Total schools: {totalItems}</span>
        </Col>
      </Row>
      <Table responsive bordered striped hover size="sm" className="mb-2">
        <thead>
          <tr>
            <th>Name</th>
            <th>Contact email</th>
            <th>Domain</th>
            <th>Admins</th>
            <th>Students</th>
            <th>Teachers</th>
            <th>Assistants</th>
            <th>Pricing plan</th>
            <th>District</th>
            <th className="text-center"></th>
          </tr>
        </thead>
        <tbody>
          {schoolList?.map((school) => (
            <tr key={school?.id}>
              <td>
                <Link to={`${SCHOOL_DETAIL_URL}/${school.id}`}>
                  {school.name}
                </Link>
              </td>
              <td>{school.contactPersonEmail}</td>
              <td>{`${school.subDomain}.marioforme.com`}</td>
              <td>{school.numberOfAdmins}</td>
              <td>{school.numberOfStudents}</td>
              <td>{school.numberOfTeachers}</td>
              <td>{school.numberOfAssistants}</td>
              <td>{school.pricingPlan?.name}</td>
              <td>{school.district?.name}</td>
              <td className="text-center">
                <DeleteButtonIcon
                  onClick={() => removeData(school.id as number)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="text-right">
        <CustomPagination
          page={filter.page}
          limit={filter.limit}
          totalItems={totalItems}
          changePage={(page: number) => changeFilter({ page })}
          changeLimit={(limit: number) => changeFilter({ limit })}
        />
      </div>
    </div>
  )
}

export default SchoolList
