import React from "react";
import QuitPrompt from "../../../components/QuitPrompt/QuitPrompt";
import CustomTabs from "../components/CustomTabs";
import useLegalPageDetail from "../hooks/useLegalPageDetail";
import useLegalPageList from "../hooks/useLegalPageList";

const LegalPageList = () => {
  const {
    legalPages,
    activeKey,
    seclectedData,
    setActiveKey,
    getData
  } = useLegalPageList()
  const 
  { 
    legalPage,
    edited,
    confirm,
    setEditedTrue,
    backToFirst
  } = useLegalPageDetail({
    defaulValue: seclectedData,
    getListData: getData,
    activeKey
  })
  return (
    <div className="animated fadeIn">
      <QuitPrompt when={edited} />
      <h4>Legal page</h4>
      {
        legalPages.length > 0
        ?
        <div className="card">
          <CustomTabs
              data={legalPages}
              dataSelected={legalPage}
              activeKey={activeKey}
              setActiveKey={setActiveKey}
              confirmData={confirm}
              setEditedTrue={setEditedTrue}
              onBack={backToFirst}
            />
        </div>
        :
        <p>No data</p>
      }
    </div>
  );
};

export default LegalPageList;
