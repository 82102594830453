import React, { FC, useState, useEffect, useCallback } from "react"

import CommonSelector from "../../../components/Selectors/CommonSelector"
import { get } from "../../../services/pricingPlanService"

interface Props {
  pricingPlanId?: number | null
  onChange: Function
}

interface PricingPlanOption {
  value: number
  label: string
  numberOfAdmins: number
  numberOfAssistants: number
  numberOfStudents: number
  numberOfTeachers: number
}

const PricingPlanSelector: FC<Props> = ({ pricingPlanId, onChange }: Props) => {
  const [options, setOptions] = useState<PricingPlanOption[]>([])

  useEffect(() => {
    getData()
  }, [])

  const getData = useCallback(async () => {
    try {
      const res = await get()
      setOptions(
        res.data.items.map((item: any) => ({
          value: item.id,
          label: item.name,
          numberOfAdmins: item.numberOfAdmins,
          numberOfAssistants: item.numberOfAssistants,
          numberOfStudents: item.numberOfStudents,
          numberOfTeachers: item.numberOfTeachers,
        }))
      )
    } catch (err) {
      console.error(err)
    }
  }, [])

  return (
    <CommonSelector
      defaultValue={pricingPlanId}
      options={options}
      onChange={onChange}
      isClearable
    />
  )
}

export default PricingPlanSelector
