import { createReducer } from "@reduxjs/toolkit"

import { setLoading, setModal, setUser, setSidebar, setAlert } from "./action"

const initialState: any = {
  sidebarShow: "responsive",
  isLoading: false,
  alert: {
    type: "",
    message: "",
  },
  modal: {
    isOpen: false,
  },
  user: null,
}

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setLoading, (state, action) => {
      state.isLoading = action.payload
    })
    .addCase(setModal, (state, action) => {
      state.modal = action.payload
    })
    .addCase(setUser, (state, action) => {
      state.user = action.payload
    })
    .addCase(setSidebar, (state, action) => {
      state.sidebarShow = action.payload
    })
    .addCase(setAlert, (state, action) => {
      state.alert = action.payload
    })
})

export default reducer
