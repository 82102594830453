import { createAction } from "@reduxjs/toolkit"

import { Notification } from "../../types/Notification"

interface NotificationList {
  items: Notification[]
  totalItems: number
}

export const setNotificationList = createAction<NotificationList>(
  "notifications/setNotificationList"
)

export const setUnreadItems = createAction<NotificationList>(
  "notifications/setUnreadItems"
)
