import { createReducer } from "@reduxjs/toolkit"

import { setDistrictList } from "./action"

const initialState: any = {
  districtList: [],
  totalItems: 0,
}

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(setDistrictList, (state, action) => {
    state.districtList = action.payload.items
    state.totalItems = action.payload.totalItems
  })
})

export default reducer
