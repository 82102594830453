import React from "react";
import { Button, Col, Row } from "reactstrap";
import FormGuide from "../Components/FormGuide";
import useGuide from "../hooks/useGuide";
import { GuideType } from "../constants/type";

const Guide = () => {
  const {
    handleChangeLink,
    handelUpLoadFile,
    getSourceByType,
    handleUpdateGuide,
    guide
  } = useGuide();
  return (
    <Row>
      <Col xs={12} md={7} className="mb-4">
        <FormGuide
          title="Guide check in"
          inputId={GuideType.CheckIn}
          inputLink={getSourceByType(GuideType.CheckIn)}
          onChangeLink={handleChangeLink}
          onSelectFile={handelUpLoadFile}
        />
      </Col>

      <Col xs={12} md={7} className="mb-4">
        <FormGuide
          title="Guide data dashboard"
          inputId={GuideType.Dashboard}
          inputLink={getSourceByType(GuideType.Dashboard)}
          onChangeLink={handleChangeLink}
          onSelectFile={handelUpLoadFile}
        />
      </Col>

      <Col xs={12} md={7} className="mb-4">
        <FormGuide
          title="Guide Plp"
          inputId={GuideType.Plp}
          inputLink={getSourceByType(GuideType.Plp)}
          onChangeLink={handleChangeLink}
          onSelectFile={handelUpLoadFile}
        />
      </Col>

      <Col xs={12} md={7} className="mb-4">
        <FormGuide
          title="Guide Conferences"
          inputId={GuideType.Conference}
          inputLink={getSourceByType(GuideType.Conference)}
          onChangeLink={handleChangeLink}
          onSelectFile={handelUpLoadFile}
        />
      </Col>

      <Col xs={12} md={7} className="mb-4">
        <FormGuide
          title="Guide Survey"
          inputId={GuideType.Survey}
          inputLink={getSourceByType(GuideType.Survey)}
          onChangeLink={handleChangeLink}
          onSelectFile={handelUpLoadFile}
        />
      </Col>

      <Col xs={12} md={7} className="mb-4">
        <FormGuide
          title="Guide Class Reflections"
          inputId={GuideType.ClassReflection}
          inputLink={getSourceByType(GuideType.ClassReflection)}
          onChangeLink={handleChangeLink}
          onSelectFile={handelUpLoadFile}
        />
      </Col>

      <Col xs={12} md={7} className="mb-4">
        <Button onClick={() => handleUpdateGuide(guide)}>Update</Button>
      </Col>
    </Row>
  );
};

export default Guide;
