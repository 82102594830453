import { setModal } from "../../../redux/commons/action"
import { useState, useCallback, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setLoading, setAlert } from "../../../redux/commons/action"
import { setGallery, setTargetMedia } from "../../../redux/gallery/action"
import { FilterType } from "../constants/gallery.types"
import {
    getMedia,
    renameFile,
    deleteFile,
    deleteFolder,
    createFolder,
    customKeyUpload
} from "../../../services/fileServices"
import { S3Object } from "../constants/gallery.types"
import getFileUrl from "../../../utils/getFileUrl"

const TITLE = "Library"

const defaultFilter: FilterType = {
    searchString: ""
}

interface FormDataMediaType {
    file: File
    key?: string
}

interface RouterType {
    link: string
    step: number
}

const useGalleryList = () => {
    const mediaList = useSelector((state: any) => state.gallery.mediaList)
    const totalItems = useSelector((state: any) => state.gallery.totalItems)
    const targetMedia: S3Object = useSelector(
        (state: any) => state.gallery.targetMedia
    )
    const dispatch = useDispatch()

    const [formDataMediaType, handleSelectFile] = useState<FormDataMediaType>()
    const [filters, setFilters] = useState<FilterType>({ ...defaultFilter })
    const [router, changeRouter] = useState<RouterType>({ link: "", step: 0 })

    const handleOpenFolder = (media: S3Object) => {
        if (media.isFolder) {
            setFilters({ prefix: media.key })
            if (media.key) {
                changeRouter({
                    link: media.key,
                    step: router.step + 1
                })
            }
        }
    }

    const handleGoBack = () => {
        const { link, step } = router
        if (!link || link == "" || step < 1) return
        let temp = link.split("/")
        if (temp[temp.length - 1] === "") {
            changeRouter({
                link: `${temp.splice(0, temp.length - 2).join("/")}/`,
                step: step - 1
            })
        } else {
            temp.pop()
            changeRouter({ link: `${temp.join("/")}/`, step: step - 1 })
        }
    }

    const handleSelectMedia = (media: S3Object) => {
        dispatch(setTargetMedia({ targetMedia: undefined }))
        if (targetMedia) {
            if (media.key === targetMedia.key) {
                dispatch(setTargetMedia({ targetMedia: undefined }))
            } else {
                dispatch(setTargetMedia({ targetMedia: media }))
            }
        } else {
            dispatch(setTargetMedia({ targetMedia: media }))
        }
    }

    const handleRenameFile = async (name: string) => {
        if (targetMedia?.key && name) {
            dispatch(setLoading(true))
            await renameFile(targetMedia.key, name)
            dispatch(setTargetMedia({ targetMedia: undefined }))
            dispatch(setLoading(false))
            getData()
        }
    }

    const handleDownloadMedia = () => {
        if (targetMedia?.key) {
            const url = getFileUrl(targetMedia.key)
            window.open(url, "_blank")
        } else {
            dispatch(
                setAlert({
                    type: "danger",
                    message: "Error to download media, please try later."
                })
            )
        }
    }

    const showErrorAlert = (message: string) => {
        setAlert({ type: "danger", message })
    }

    const handleDeleteMediaOrFolder = async () => {
        if (targetMedia?.key) {
            dispatch(
                setModal({
                    isOpen: true,
                    type: "warning",
                    message: `Do you want to remove this ${
                        targetMedia.isFolder ? "folder" : "file"
                    }`,
                    onConfirm: async () => {
                        dispatch(setLoading(true))
                        if (targetMedia.isFolder) {
                            await deleteFolder(targetMedia.key || "")
                            dispatch(setTargetMedia({ targetMedia: undefined }))
                        } else {
                            await deleteFile(targetMedia?.key || "")
                            dispatch(setTargetMedia({ targetMedia: undefined }))
                        }
                        dispatch(setLoading(false))
                        getData()
                    }
                })
            )
        } else {
            dispatch(
                setAlert({
                    type: "danger",
                    message: "Error to delete media, please try later."
                })
            )
        }
    }

    const handleCreateFolder = async (prefix: string, folderName: string) => {
        if (folderName) {
            await createFolder(prefix, folderName)
            getData()
        }
    }

    const changeFilters = (updatedFilters: FilterType) =>
        setFilters({
            ...filters,
            ...updatedFilters
        })

    const getData = useCallback(async () => {
        dispatch(setLoading(true))
        try {
            const res = await getMedia(filters)
            dispatch(
                setGallery({
                    mediaList: res.data,
                    totalItems: res.data.length || 0
                })
            )
        } catch (err) {
            dispatch(
                setAlert({
                    type: "danger",
                    message: err.response?.data?.title || err.message
                })
            )
        }
        dispatch(setLoading(false))
    }, [dispatch, filters])

    useEffect(() => {
        document.title = TITLE
    }, [])

    useEffect(() => {
        changeFilters({ prefix: router.link })
    }, [router.link])

    useEffect(() => {
        getData()
    }, [filters])

    useEffect(() => {
        async function customKeyUploadEffect() {
            dispatch(setLoading(true))
            try {
                if (formDataMediaType) {
                    const { file, key } = formDataMediaType
                    const { name } = file
                    const customKey = key
                    const formData = new FormData()
                    formData.append("file", file, name)
                    formData.append("customKey", customKey?.slice(0, -1) || "")
                    await customKeyUpload(formData)
                    getData()
                }
            } catch (err) {
                dispatch(
                    setAlert({
                        type: "danger",
                        message: err.response?.data?.title || err.message
                    })
                )
            }
            dispatch(setLoading(false))
        }

        customKeyUploadEffect()
    }, [formDataMediaType])

    const breadcrumb = useMemo(() => {
        try {
            const subLinks = router.link?.split("/")
            return subLinks.splice(1, subLinks.length).join("/")
        } catch (_err) {
            return ""
        }
    }, [router.link])

    return {
        mediaList,
        filters,
        totalItems,
        targetMedia,
        router,
        breadcrumb,
        getData,
        changeFilters,
        handleSelectFile,
        handleOpenFolder,
        handleSelectMedia,
        handleRenameFile,
        handleDownloadMedia,
        handleDeleteMediaOrFolder,
        handleCreateFolder,
        handleGoBack,
        showErrorAlert
    }
}

export default useGalleryList
