import React, { FC, memo } from "react"

import CommonSelector from "../../../components/Selectors/CommonSelector"

const options = [
  { value: true, label: "Read" },
  { value: false, label: "Unread" },
]

interface Props {
  value?: boolean | null
  onChange: Function
}

const NotificationStatusSelector: FC<Props> = ({ value, onChange }: Props) => {
  return (
    <CommonSelector
      defaultValue={value}
      options={options}
      onChange={onChange}
      placeholder="Notification status"
      isClearable
    />
  )
}

export default memo(NotificationStatusSelector)
