import React, { FC, useCallback } from "react"
import { FaBell } from "react-icons/fa"

import useUnreadNotification from "../hooks/useUnreadNotification"

const ICON_SIZE = 20
const NotificationButton: FC = () => {
  const { unreadItems } = useUnreadNotification()

  const openNotification = useCallback(() => {
    const features = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, width=1080, height=720, top=100, left=100`
    const url = `/notifications`
    window.open(url, "NotificationDialog", features)
  }, [])

  return (
    <div className="d-inline position-relative cursor-pointer mr-2 user-select-none">
      <div className="d-inline position-relative" onClick={openNotification}>
        <FaBell size={ICON_SIZE} />
        {!!unreadItems && (
          <div className={`notification-count position-absolute text-sm`}>
            {unreadItems}
          </div>
        )}
      </div>
    </div>
  )
}

export default NotificationButton
