import Images from "../../../assets/constants/Images";
import getFileUrl from '../../../utils/getFileUrl';
import { MediaType, S3Object } from '../constants/gallery.types'

const images = ["png", "jpg", "jpeg", "gif"]
const videos = ["mp4", "mp4v"]
const docs = ["txt", "pdf", "doc", "docx", "xls", "csv"]
const audios = ["wav", "mp3"]


export const getTypeByExtension: (extension: string | undefined) => MediaType = (extension) => {
  if(extension){
    if(images.includes(extension)) return MediaType.IMAGE
    if(videos.includes(extension)) return MediaType.VIDEO
    if(docs.includes(extension)) return MediaType.DOC
    if(audios.includes(extension)) return MediaType.AUDIO
  }
  return MediaType.UNKNOW
}

export const getMediaThumbnail = (media: S3Object) => {
  if(media.isFolder) return Images.FOLDER_IMAGE;
  const type = getTypeByExtension(media.extension)

  if(type === MediaType.IMAGE){
    return Images.PHOTO_IMAGE
  }
  if(type === MediaType.VIDEO){
    return Images.VIDEO_IMAGE
  }
  if(type === MediaType.AUDIO){
    return Images.AUDIO_IMAGE
  }
  if(type === MediaType.DOC){
    return Images.DOCS_IMAGE
  }
  return Images.UNKNOW_IMAGE;
}

export const getMediaImage = (media: S3Object) => {
  if(media.isFolder) return Images.FOLDER_IMAGE;
  const type = getTypeByExtension(media.extension)

  if(type === MediaType.IMAGE){
    return getFileUrl(media.key || "")
  }
  if(type === MediaType.VIDEO){
    return Images.VIDEO_IMAGE
  }
  if(type === MediaType.AUDIO){
    return Images.AUDIO_IMAGE
  }
  if(type === MediaType.DOC){
    return Images.DOCS_IMAGE
  }
  return "";
}
